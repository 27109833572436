import { gql } from '@apollo/client';

export const complaintFragment = gql`
  fragment ComplaintFields on Complaint {
    dateCreated
    personalDataOfTheComplainant {
      lastName
      telephone
      city
      postcode
      address
      country
      registrationNumberAndLEI
      firstName
      email
    }
    contactDetailsOfComplainant {
      lastName
      telephone
      city
      postcode
      address
      country
      firstName
      email
    }
    personalDataOfTheLegalRepresentative {
      lastName
      telephone
      city
      postcode
      address
      country
      registrationNumberAndLEI
      firstName
      email
    }
    contactDetailsOfLegalRepresentative {
      lastName
      telephone
      city
      postcode
      address
      country
      firstName
      email
    }
    referenceOfInvestmentOrAgreement
    complaintSubjectMatter
    datesOfFacts
    descriptionOfDamage
    otherInformation
    powerOfAttorney {
      data {
        attributes {
          url
        }
      }
    }
    contractualDocuments {
      data {
        attributes {
          url
        }
      }
    }
    otherDocuments {
      data {
        attributes {
          url
        }
      }
    }
    commsAccepted
  }
`;

export const GET_COMPLAINT_QUERY = gql`
  ${complaintFragment}
  query getComplaint($id: ID!) {
    getComplaint(id: $id) {
      data {
        id
        attributes {
          ...ComplaintFields
        }
      }
    }
  }
`;

export const CREATE_COMPLAINT_QUERY = gql`
  ${complaintFragment}
  mutation createComplaint(
    $personalDataOfTheComplainant: ComponentComplaintPersonalDataInput
    $contactDetailsOfComplainant: ComponentComplaintPersonalDataInput
    $personalDataOfTheLegalRepresentative: ComponentComplaintPersonalDataInput
    $contactDetailsOfLegalRepresentative: ComponentComplaintPersonalDataInput
    $referenceOfInvestmentOrAgreement: String
    $complaintSubjectMatter: String
    $datesOfFacts: String
    $descriptionOfDamage: String
    $otherInformation: String
    $commsAccepted: Boolean
    $dateCreated: DateTime
  ) {
    createComplaint(
      data: {
        personalDataOfTheComplainant: $personalDataOfTheComplainant
        contactDetailsOfComplainant: $contactDetailsOfComplainant
        personalDataOfTheLegalRepresentative: $personalDataOfTheLegalRepresentative
        contactDetailsOfLegalRepresentative: $contactDetailsOfLegalRepresentative
        referenceOfInvestmentOrAgreement: $referenceOfInvestmentOrAgreement
        complaintSubjectMatter: $complaintSubjectMatter
        datesOfFacts: $datesOfFacts
        descriptionOfDamage: $descriptionOfDamage
        otherInformation: $otherInformation
        commsAccepted: $commsAccepted
        dateCreated: $dateCreated
      }
    ) {
      data {
        id
        attributes {
          ...ComplaintFields
        }
      }
    }
  }
`;

export const UPDATE_COMPLAINT_QUERY = gql`
  ${complaintFragment}
  mutation updateComplaint(
    $id: ID!
    $personalDataOfTheComplainant: editComponentComplaintPersonalDataOfTheComplainantInput
    $contactDetailsOfComplainant: editComponentComplaintPersonalDataOfTheComplainantInput
    $personalDataOfTheLegalRepresentative: editComponentComplaintPersonalDataOfTheComplainantInput
    $contactDetailsOfLegalRepresentative: editComponentComplaintPersonalDataOfTheComplainantInput
    $referenceOfInvestmentOrAgreement: String
    $complaintSubjectMatter: String
    $datesOfFacts: String
    $descriptionOfDamage: String
    $otherInformation: String
  ) {
    updateComplaint(
      id: $id
      data: {
        personalDataOfTheComplainant: $personalDataOfTheComplainant
        contactDetailsOfComplainant: $contactDetailsOfComplainant
        personalDataOfTheLegalRepresentative: $personalDataOfTheLegalRepresentative
        contactDetailsOfLegalRepresentative: $contactDetailsOfLegalRepresentative
        referenceOfInvestmentOrAgreement: $referenceOfInvestmentOrAgreement
        complaintSubjectMatter: $complaintSubjectMatter
        datesOfFacts: $datesOfFacts
        descriptionOfDamage: $descriptionOfDamage
        otherInformation: $otherInformation
      }
    ) {
      data {
        id
        attributes {
          ...ComplaintFields
        }
      }
    }
  }
`;

export const DELETE_COMPLAINT_QUERY = gql`
  mutation deleteComplaint($id: ID!) {
    deleteComplaint(id: $id) {
      data {
        id
      }
    }
  }
`;
