import {
  ClockCircleOutlined,
  CheckCircleFilled,
  ExclamationCircleFilled,
  WarningFilled,
} from '@ant-design/icons';

import { PaymentStatus } from 'utils/oppHelpers';

import theme from 'styles/theme';

interface StatusUI {
  message: string | React.ReactNode;
  status: 'wait' | 'process' | 'finish' | 'error';
}

/**
 * Returns UI elements for KYC verification status
 * @param status - The KYC verification status
 * @returns StatusUI object containing message, icon, and color
 */
export const getKYCStatusUI = (
  status: string | undefined,
  error?: boolean
): StatusUI => {
  if (error) {
    return {
      message: 'KYC verification error',
      status: 'error',
    };
  }

  switch (status) {
    case 'verified':
      return {
        message: (
          <>
            Identity verification complete{' '}
            <CheckCircleFilled style={{ color: theme.colors.success }} />
          </>
        ),
        status: 'finish',
      };
    case 'pending':
      return {
        message: 'Identity verification pending',
        status: 'finish',
      };
    default:
      return {
        message: 'Verify your identity',
        status: 'process',
      };
  }
};

export function getBankAccountDisapprovalReason(reason: string): {
  title: string;
  description: string;
} {
  switch (reason) {
    case 'bank_account_business':
      return {
        title: 'Consumer bank account needed',
        description:
          "We noticed you've provided a business bank account, but for this type of merchant account, we need a consumer bank account. Could you please provide your personal bank account details instead?",
      };
    case 'bank_account_creditcard_without_iban':
      return {
        title: 'IBAN information needed',
        description:
          "We see you've provided credit card information, but we need to see the associated IBAN. Could you please provide a document that clearly shows the IBAN for this account?",
      };
    case 'bank_account_document_both_sides_needed':
      return {
        title: 'Both sides of bank card needed',
        description:
          "We'll need to see both sides of your bank card to proceed. Would you mind uploading images of both the front and back of your card?",
      };
    case 'bank_account_document_expired':
      return {
        title: 'Recent bank statement needed',
        description:
          "The bank statement you've provided is more than 3 months old. Could you please share a more recent statement from the last 3 months?",
      };
    case 'bank_account_document_front_missing':
      return {
        title: 'Front of bank card needed',
        description:
          "We're missing the front side of your bank card. Could you please upload an image of the front of your card so we can complete the verification?",
      };
    case 'bank_account_document_incorrect':
      return {
        title: 'Different document needed',
        description:
          "The document you've provided isn't quite what we need. Could you please provide either a bank statement or a clear image of your bank card?",
      };
    case 'bank_account_document_unreadable':
      return {
        title: 'Clearer document needed',
        description:
          "We're having trouble reading the bank document you've provided. Could you please upload a clearer image where all the details are easily readable?",
      };
    case 'bank_account_from_unlicensed_country':
      return {
        title: 'Bank account country restriction',
        description:
          "We've noticed your bank account is from a country where we're not currently licensed to operate. We can only process payments for accounts within our licensed regions. Please provide a bank account from one of our supported countries.",
      };
    case 'bank_account_iban_different_from_document':
      return {
        title: 'IBAN mismatch',
        description:
          "The IBAN you've provided doesn't match the one shown on your bank document. Could you please double-check and ensure both IBANs are the same?",
      };
    case 'bank_account_is_not_allowed':
      return {
        title: 'Bank account type restriction',
        description:
          "The type of bank account you've provided isn't compatible with your business type. Please contact our support team for guidance on which type of account you should use.",
      };
    case 'bank_account_joint_not_allowed':
      return {
        title: 'Joint account not accepted',
        description:
          "We noticed you've provided a joint bank account, which we can't accept for your type of business. Could you please provide an individual account instead?",
      };
    case 'bank_does_not_match_coc_name':
      return {
        title: 'Name mismatch on documents',
        description:
          "The name on your bank documents doesn't match the name on your Chamber of Commerce registration. Could you please provide bank documents that match your business registration name?",
      };
    case 'other_reason':
      return {
        title: 'Additional verification needed',
        description:
          "We're unable to verify your bank account for a reason not listed above. Please contact our support team for more information and guidance on how to proceed.",
      };
    default:
      return {
        title: 'Verification needed',
        description:
          "We're having trouble verifying your bank account. Please contact our support team for assistance with your specific situation.",
      };
  }
}

export function getKycDisapprovalReason(reason: string): {
  title: string;
  description: string;
} {
  switch (reason) {
    case 'contact_document_both_sides_needed':
      return {
        title: 'Both sides of your document needed',
        description:
          'It looks like only one side of your ID document was uploaded. Please upload both the front and back to continue with the onboarding process.',
      };
    case 'contact_document_expired':
      return {
        title: 'Document expired',
        description:
          'It appears that your ID document has expired. Could you upload a valid document to proceed?',
      };
    case 'contact_document_incorrect':
      return {
        title: 'Incorrect document',
        description:
          "The document you provided isn't quite right and we're unable to process it. Could you double-check and upload the correct one?",
      };
    case 'contact_document_unreadable':
      return {
        title: 'Document unreadable',
        description:
          "It seems like we're having trouble reading your document. Could you upload a clearer version so we can continue?",
      };
    case 'contact_id_does_not_match_bank_account':
      return {
        title: "ID doesn't match bank account",
        description:
          "The name on your ID doesn't match the one on your bank account. Could you provide an ID that matches your bank account name?",
      };
    case 'contact_id_does_not_match_joint_bank_account':
      return {
        title: "ID doesn't match joint bank account",
        description:
          "The name on your ID isn't listed on the joint bank account we have on file. Could you upload an ID that matches, or a copy of your bank card showing you as an account owner?",
      };
    case 'contact_id_does_not_match_legal_representative':
      return {
        title: "ID doesn't match legal representative",
        description:
          "The ID provided doesn't match any of the legal representatives on your business registration. Could you provide an ID for one of the listed representatives?",
      };
    case 'contact_proof_of_address':
      return {
        title: 'Proof of address needed',
        description:
          'The ID document provided is from outside our licensed countries. Could you upload proof that you live within our licensed area to proceed?',
      };
    case 'contact_under_18_years_of_age':
      return {
        title: 'Under 18 years of age',
        description:
          "Our records indicate you're under 18. In some cases, special agreements with certain platforms might allow us to proceed, but age restrictions may apply.",
      };
    case 'other_reason':
      return {
        title: 'Other reason',
        description:
          "Your ID verification was declined for a reason not covered here. Please contact support if you'd like more information.",
      };
    default:
      return {
        title: 'Unknown reason',
        description:
          "The provided reason code doesn't match any known reason. Please double-check the code or contact us for help.",
      };
  }
}

/**
 * Returns UI elements for membership payment status
 * @param status - The membership payment status
 * @returns PaymentStatusUI object containing message, icon, and color
 */
export const getPaymentStatusUI = (status: PaymentStatus): StatusUI => {
  switch (status) {
    case PaymentStatus.Processing:
      return {
        message: 'Your payment is being processed',
        status: 'finish',
      };
    case PaymentStatus.Active:
      return {
        message: (
          <>
            Membership payment complete{' '}
            <CheckCircleFilled style={{ color: theme.colors.success }} />
          </>
        ),
        status: 'finish',
      };
    case PaymentStatus.Failed:
      return {
        message: 'Membership payment failed!',
        status: 'error',
      };
    default:
      return {
        message: 'Pay your membership fees',
        status: 'process',
      };
  }
};
