import styled from 'styled-components';
import { Typography } from 'antd';
import { timingFunctions } from 'polished';
import Link from 'next/link';
import Image from 'next/image';

import theme from 'styles/theme';

interface CommonProps {
  $isInView?: boolean;
  $isDarkBackground?: boolean;
  $isSolidBackground?: boolean;
  $isHome?: boolean;
  $isMobile?: boolean;
}

export const HeaderContainer = styled.div<CommonProps>`
  background-color: ${(props) =>
    props.$isSolidBackground && props.$isMobile
      ? props.$isDarkBackground
        ? theme.colors.black
        : theme.colors.white
      : 'transparent'};
  position: ${(props) => (props.$isHome ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  opacity: ${(props) => (props.$isInView ? 1 : 0)};
  transform: ${(props) =>
    props.$isInView ? 'translateY(0px)' : 'translateY(-25px)'};

  transition:
    opacity 0.5s ${timingFunctions('easeOutQuad')} 1s,
    transform 0.5s ${timingFunctions('easeOutQuad')} 1s,

  &:after {
    content: '';
    display: ${(props) => (props.$isSolidBackground ? 'block' : 'none')};
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    height: 50px;
    background: ${(props) =>
      props.$isDarkBackground
        ? 'linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)'
        : 'linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)'};
    pointer-events: none;
  }
`;

export const Logo = styled(Image)<CommonProps>`
  width: 30px;
  height: auto;
  filter: ${(props) => (props.$isDarkBackground ? 'none' : 'invert(1)')};
  transition: filter 0.7s ease-out;
`;

export const DropDownActiveItem = styled(Typography.Text)<CommonProps>`
  &&& {
    font-family: 'Aeonik';
    font-weight: 700;
    color: ${(props) =>
      props.$isDarkBackground ? theme.colors.white : theme.colors.black};
    cursor: default;
  }
`;

interface MainLinkProps {
  readonly $isActive: boolean;
}

export const MainLink = styled(Link)<MainLinkProps>`
  &&& {
    font-family: 'Aeonik';
    font-weight: 700;
    color: ${(props) =>
      props.$isActive
        ? theme.colors.textSecondary
        : theme.colors.darkTeal} !important;
    pointer-events: ${(props) =>
      props.$isActive ? 'none' : 'auto'} !important;
    cursor: ${(props) => (props.$isActive ? 'default' : 'pointer')} !important;
  }
`;

export const MainLinkNoHref = styled(Typography.Link)<CommonProps>`
  &&& {
    font-family: 'Aeonik';
    font-weight: 700;
    color: ${(props) =>
      props.$isDarkBackground ? theme.colors.white : theme.colors.black};
  }
`;

interface BurgerButtonProps {
  $isOpen: boolean;
  $isDarkBackground: boolean;
}

export const BurgerLine = styled.div<BurgerButtonProps>`
  width: 100%;
  height: 2px;
  background: ${theme.colors.white};
  border-radius: 10px;
  transition:
    background-color 0.3s ease-out ${({ $isOpen }) => ($isOpen ? '0s' : '0.6s')},
    transform 0.7s ${timingFunctions('easeOutQuint')};
  position: relative;
  transform-origin: center;

  background-color: ${(props) =>
    props.$isOpen
      ? theme.colors.black
      : props.$isDarkBackground
        ? theme.colors.white
        : theme.colors.black};

  &:first-of-type {
    transform: ${({ $isOpen }) =>
      $isOpen ? 'translateY(9px) rotate(45deg)' : 'rotate(0)'};
  }

  &:nth-of-type(2) {
    opacity: ${({ $isOpen }) => ($isOpen ? 0 : 1)};
    width: 31px;
    margin-left: -3px;
  }

  &:last-of-type {
    transform: ${({ $isOpen }) =>
      $isOpen ? 'translateY(-8px) rotate(-45deg)' : 'rotate(0)'};
  }
`;

export const BurgerButton = styled.button<BurgerButtonProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 25px;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  transition:
    height 0.1s ease-in-out,
    transform 0.1s ease-in-out,
    background-color 0.3s ease-in-out;

  &:hover {
    height: ${(props) => (props.$isOpen ? '25px' : '28px')};
    transform: scale(1.1);
  }

  &:active {
    height: 25px;
    transform: scale(1);
  }

  &:focus {
    outline: none;
  }
`;

export const SignInLink = styled(Link)<CommonProps>`
  &&& {
    color: ${(props) =>
      props.$isDarkBackground ? theme.colors.white : theme.colors.black};
    opacity: 0.8;
    transition:
      opacity 0.2s ease-out,
      color 0.2s ease-out;
    font-weight: 500;

    &:hover {
      opacity: 1;
    }
  }
`;
