import { useMemo, useState, useEffect, useRef } from 'react';
import { Badge, Drawer, List, Spin, Grid } from 'antd';
import { BellFilled } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { useSession } from 'next-auth/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { motion, AnimatePresence } from 'framer-motion';

import { NotificationProps } from 'types/notification';

import { NOTIFICATIONS_QUERY } from 'queries';

import Notification from 'components/Notification';

import { flattenEntities } from 'utils/graphql';
import { useMenu } from 'context/MenuContext';
import { useLogoState } from 'context/LogoContext';

import theme from 'styles/theme';

const LIMIT = 20;

interface NotificationsProps {}

function Notifications({}: NotificationsProps) {
  const screens = Grid.useBreakpoint();
  const { data: session } = useSession();
  const bellRef = useRef(null);

  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const { isMenuOpen } = useMenu();
  const { isDarkBackground } = useLogoState();

  const { data, fetchMore } = useQuery(NOTIFICATIONS_QUERY, {
    skip: !session,
    pollInterval: 30000,
    variables: {
      pagination: {
        start: 0,
        limit: LIMIT,
      },
      filters: {
        to: { id: { eq: session?.user.id } },
      },
    },
  });
  const notifications: NotificationProps[] = useMemo(() => {
    return flattenEntities(data?.notifications)?.data || [];
  }, [data?.notifications]);
  const notificationsCount: number = useMemo(() => {
    return flattenEntities(data?.notifications)?.pagination?.total || [];
  }, [data?.notifications]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        !event.target.closest('.ant-drawer-content-wrapper') &&
        !event.target.closest('.Notification-ErrorButton') &&
        !event.target.closest('.Notification-ReadMoreButton') &&
        !bellRef.current?.contains(event.target)
      ) {
        setNotificationsOpen(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [bellRef]);

  const loadMoreData = () => {
    fetchMore({
      variables: {
        start: notifications.length,
        limit: LIMIT,
        where: {
          user: session?.user.id,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        return {
          notificationsCount: fetchMoreResult.notificationsCount,
          notifications: [
            ...previousResult.notifications,
            ...fetchMoreResult.notifications,
          ],
        };
      },
    });
  };

  const popoverContent = (
    <div
      id="scrollableDiv"
      style={{ height: 'calc(100vh - 78px', overflow: 'auto' }}
    >
      <InfiniteScroll
        scrollableTarget="scrollableDiv"
        dataLength={notifications.length}
        next={loadMoreData}
        hasChildren={true}
        hasMore={notifications.length < notificationsCount}
        loader={
          <Spin
            style={{ display: 'block', margin: '20px auto' }}
            size="small"
          />
        }
        endMessage={null}
      >
        <List>
          <AnimatePresence>
            {notifications.map((item: NotificationProps) => (
              <motion.div
                key={item.id}
                layout
                initial={{ opacity: 1, height: 'auto' }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{
                  opacity: 0,
                  height: 0,
                  overflow: 'hidden',
                  transition: { duration: 0.25 },
                }}
              >
                <Notification
                  notification={item}
                  limit={LIMIT}
                  setNotificationsOpen={setNotificationsOpen}
                />
              </motion.div>
            ))}
          </AnimatePresence>
        </List>
      </InfiniteScroll>
    </div>
  );

  return (
    <>
      <Drawer
        rootClassName="Notification-Drawer"
        open={notificationsOpen}
        placement="right"
        width={screens.md ? '450px' : '100%'}
        styles={{ body: { padding: 0 } }}
        title="Notifications"
        destroyOnClose
        onClose={() => setNotificationsOpen(false)}
      >
        {popoverContent}
      </Drawer>
      <Badge
        count={notificationsCount}
        offset={[3, -3]}
        size="small"
        style={{
          backgroundColor: theme.colors.chartreuse,
          color: theme.colors.black,
          borderColor: 'transparent',
          fontSize: 11,
          fontWeight: 500,
          opacity: isMenuOpen ? 0 : 1,
          transition: `opacity 0.3s ease-out ${isMenuOpen ? '0s' : '0.7s'}`,
        }}
      >
        <BellFilled
          ref={bellRef}
          onClick={() => setNotificationsOpen(!notificationsOpen)}
          style={{
            fontSize: 22,
            color: isDarkBackground ? theme.colors.white : theme.colors.black,
            opacity: isMenuOpen ? 0 : 1,
            pointerEvents: isMenuOpen ? 'none' : 'auto',
            transition: `opacity 0.3s ease-out ${isMenuOpen ? '0s' : '0.7s'}`,
          }}
        />
      </Badge>
    </>
  );
}

export default Notifications;
