import { useState, useRef, useEffect } from 'react';
import { Drawer, Grid, Tour, Typography } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import useSWR from 'swr';
import type { TourProps } from 'antd';

import { Merchant } from 'types/opp';
import { getQuarterlyInvestmentStatus, PaymentStatus } from 'utils/oppHelpers';

import RenewalPaymentModal from 'components/Account/Details/RenewalPaymentModal';
import QuarterlyInvestmentModal from 'components/Account/Stats/UpdateQuarterlySubscription';
import KYCModal from './KYCModal';

import useUserData from 'hooks/useUserData';
import { getPaymentStatus, merchantEndpoint } from 'utils/oppHelpers';
import { getPaymentStatusUI, getKYCStatusUI } from './statusHelpers';
import { useWelcomeModal } from 'context/WelcomeModalContext';
import { useMenu } from 'context/MenuContext';
import { useLogoState } from 'context/LogoContext';

import theme from 'styles/theme';
import {
  OnboardingChecklistIcon,
  OnboardingChecklistWrapper,
  OnboardingChecklistSteps,
  OnboardingChecklistStepsCta,
} from './styles';

const TOUR_SHOWN_KEY = 'Shuttle-tour-shown';

function OnboardingChecklist() {
  const screens = Grid.useBreakpoint();
  const iconRef = useRef(null);

  const [checklistOpen, setChecklistOpen] = useState(false);
  const [renewalPaymentModalOpen, setRenewalPaymentModalOpen] = useState(false);
  const [kycModalOpen, setKycModalOpen] = useState(false);
  const [quarterlyInvestmentModalOpen, setQuarterlyInvestmentModalOpen] =
    useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [tourHasBeenShown, setTourHasBeenShown] = useState(false);
  const [checklistFullyOpen, setChecklistFullyOpen] = useState(false);

  const { welcomeModalOpen } = useWelcomeModal();
  const { isMenuOpen } = useMenu();
  const { isDarkBackground } = useLogoState();

  const { userData } = useUserData();

  const { data: merchant } = useSWR<Merchant>(
    userData?.OPP?.merchantId
      ? merchantEndpoint(userData?.OPP.merchantId)
      : null
  );

  useEffect(() => {
    setTourHasBeenShown(Boolean(localStorage.getItem(TOUR_SHOWN_KEY)));
  }, []);

  useEffect(() => {
    if (welcomeModalOpen || isMenuOpen || tourHasBeenShown) return;

    const tourTimeout = setTimeout(() => {
      if (!isMenuOpen) {
        setIsTourOpen(true);
        setTourHasBeenShown(true);
      }
    }, 1500);

    return () => clearTimeout(tourTimeout);
  }, [welcomeModalOpen, isMenuOpen, tourHasBeenShown]);

  /**
   * Handles clicks anywhere on the document to close the tour
   * Only adds listener when tour is open to avoid unnecessary event binding
   */
  useEffect(() => {
    const handleTourMaskClick = (event: MouseEvent) => {
      // Check if the clicked element or its ancestors have the tour mask class
      if ((event.target as Element).closest('.ant-tour-mask')) {
        handleTourClose();
      }
    };

    if (isTourOpen) {
      document.addEventListener('click', handleTourMaskClick);
    }

    return () => {
      document.removeEventListener('click', handleTourMaskClick);
    };
  }, [isTourOpen]);

  const isMobile = screens.md === false;

  const kycStatus: Merchant['contacts'][0]['status'] =
    merchant?.contacts[0]?.status;
  const kycError =
    kycStatus === 'unverified' &&
    merchant?.contacts[0]?.disapprovals.length > 0;

  const quarterlySubscription =
    userData?.investorDetails?.quarterlySubscription;
  const annualMembershipStatus = getPaymentStatus(userData?.OPP?.annualFee);
  const quarterlyInvestmentStatus = getQuarterlyInvestmentStatus(
    userData?.investorDetails
  );
  const onboardingComplete =
    annualMembershipStatus === PaymentStatus.Active &&
    kycStatus === 'verified' &&
    !!userData?.investorDetails.quarterlySubscription;

  const annualMembershipDue =
    annualMembershipStatus === PaymentStatus.Due ||
    annualMembershipStatus === PaymentStatus.Failed;
  const quarterlyInvestmentDue =
    quarterlyInvestmentStatus === PaymentStatus.Due ||
    quarterlyInvestmentStatus === PaymentStatus.Failed;
  const paymentModalPaymentType =
    annualMembershipDue && quarterlyInvestmentDue
      ? 'annualQuarterly'
      : annualMembershipDue
        ? 'annual'
        : quarterlyInvestmentDue
          ? 'quarterly'
          : null;

  const handleTourClose = () => {
    setIsTourOpen(false);
    setTourHasBeenShown(true);
    localStorage.setItem(TOUR_SHOWN_KEY, 'true');
  };

  const popoverContent = (
    <OnboardingChecklistWrapper $isMobile={isMobile}>
      <OnboardingChecklistSteps
        direction="vertical"
        size={isMobile ? 'small' : 'default'}
        $isMobile={isMobile}
        items={[
          {
            title: !!quarterlySubscription ? (
              <>
                Quarterly Investment created{' '}
                <CheckCircleFilled style={{ color: theme.colors.success }} />
              </>
            ) : (
              'Set up your Quarterly Investment'
            ),
            status: !!quarterlySubscription ? 'finish' : 'process',
            description: !quarterlySubscription && (
              <OnboardingChecklistStepsCta
                disabled={!!quarterlySubscription}
                onClick={() => setQuarterlyInvestmentModalOpen(true)}
              >
                {!!quarterlySubscription ? 'Complete' : 'Start'}
              </OnboardingChecklistStepsCta>
            ),
          },
          {
            title: getPaymentStatusUI(annualMembershipStatus).message,
            status: getPaymentStatusUI(annualMembershipStatus).status,
            description: annualMembershipStatus !== PaymentStatus.Active && (
              <OnboardingChecklistStepsCta
                disabled={annualMembershipStatus === PaymentStatus.Processing}
                danger={annualMembershipStatus === PaymentStatus.Failed}
                onClick={() => setRenewalPaymentModalOpen(true)}
              >
                {annualMembershipStatus === PaymentStatus.Failed
                  ? 'Retry'
                  : annualMembershipStatus === PaymentStatus.Processing
                    ? 'Processing...'
                    : annualMembershipStatus === PaymentStatus.Created
                      ? 'See instructions'
                      : 'Start'}
              </OnboardingChecklistStepsCta>
            ),
          },
          {
            title: getKYCStatusUI(kycStatus, kycError).message,
            status: getKYCStatusUI(kycStatus, kycError).status,
            description: kycStatus !== 'verified' && (
              <OnboardingChecklistStepsCta
                disabled={kycStatus === 'pending'}
                danger={kycError}
                onClick={() => setKycModalOpen(true)}
              >
                {kycError
                  ? 'Retry'
                  : kycStatus === 'pending'
                    ? 'Reviewing documents...'
                    : 'Start'}
              </OnboardingChecklistStepsCta>
            ),
          },
        ]}
      />
      {onboardingComplete && (
        <OnboardingChecklistStepsCta
          onClick={() => setChecklistOpen(false)}
          style={{ marginTop: 10 }}
        >
          Close
        </OnboardingChecklistStepsCta>
      )}
    </OnboardingChecklistWrapper>
  );

  const tourSteps: TourProps['steps'] = [
    {
      title: null,
      description: (
        <Typography.Paragraph
          style={{
            color: theme.colors.bodyText,
            marginBottom: -10,
            paddingTop: 10,
          }}
        >
          Complete these steps <br /> to start investing
        </Typography.Paragraph>
      ),
      target: () => iconRef.current,
      placement: 'leftTop',
      nextButtonProps: {
        style: {
          display: 'none',
        },
      },
      prevButtonProps: {
        style: {
          display: 'none',
        },
      },
      arrow: {
        pointAtCenter: false,
      },
    },
  ];

  if (onboardingComplete && !isTourOpen && !checklistFullyOpen) {
    return null;
  }

  return (
    <>
      <RenewalPaymentModal
        paymentType={paymentModalPaymentType}
        isOpen={renewalPaymentModalOpen}
        setIsOpen={setRenewalPaymentModalOpen}
      />
      <KYCModal isOpen={kycModalOpen} setIsOpen={setKycModalOpen} />
      <QuarterlyInvestmentModal
        isOpen={quarterlyInvestmentModalOpen}
        setIsOpen={setQuarterlyInvestmentModalOpen}
        userData={userData}
      />
      <Drawer
        rootClassName="Notification-Drawer"
        open={checklistOpen}
        placement="right"
        width={screens.md ? '450px' : '100%'}
        styles={{ body: { padding: 0 } }}
        title="Onboarding Checklist"
        destroyOnClose
        onClose={() => setChecklistOpen(false)}
        afterOpenChange={(open) => {
          if (!localStorage.getItem(TOUR_SHOWN_KEY)) {
            localStorage.setItem(TOUR_SHOWN_KEY, 'true');
          }
          setChecklistFullyOpen(open);
        }}
      >
        {popoverContent}
      </Drawer>
      <Tour
        open={isTourOpen}
        onClose={handleTourClose}
        steps={tourSteps}
        closeIcon={false}
      />
      <OnboardingChecklistIcon
        ref={iconRef}
        onClick={() => {
          setChecklistOpen(!checklistOpen);
          setIsTourOpen(false);
        }}
        $isDarkBackground={isDarkBackground}
        $isMenuOpen={isMenuOpen}
      />
    </>
  );
}

export default OnboardingChecklist;
