import { gql } from '@apollo/client';

export const GET_FAQ_QUERY = gql`
  {
    data {
      attributes {
        question
        answer
        category
      }
    }
  }
`;

export const GET_HOMEPAGE_FAQ_QUERY = gql`
  {
    faqs(filters: { showOnHomepage: { eq: true } }) {
      data {
        attributes {
          question
          answer
          category
        }
      }
    }
  }
`;
