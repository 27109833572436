import styled from 'styled-components';
import { Button, Row, Typography } from 'antd';
import Image from 'next/image';

import theme from 'styles/theme';

interface CommonProps {
  $isMobile?: boolean;
  $isInView?: boolean;
  $isBuildVersion?: boolean;
  $homepage?: boolean;
}

export const FooterContainer = styled.div<CommonProps>`
  padding-top: ${({ $isMobile, $homepage }) =>
    $isMobile ? ($homepage ? '70px' : '0px') : '20px'};
  background-color: ${theme.colors.white};
  overflow: ${(props) => (props.$isMobile ? 'hidden' : 'visible')};

  &:last-child {
    background-color: #f5f6fa;
    padding-top: 0px;
    padding-bottom: ${({ $isMobile, $homepage }) =>
      $isMobile ? ($homepage ? '100px' : '10px') : '0px'};
  }
`;

export const Wrapper = styled.div<CommonProps>`
  width: 100%;
  padding: ${(props) => (props.$isMobile ? '40px 30px' : '40px 60px')};
  max-width: ${theme.size.maxWidth};
  margin: 0 auto;
  position: relative;
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.primarySofter}30;
`;

export const FooterLink = styled(Typography.Link)<CommonProps>`
  &&& {
    margin-bottom: 5px;
    font-size: 24px;
    font-weight: 500;
    color: ${(props) =>
      props.$isBuildVersion ? theme.colors.textSecondary : theme.colors.black};
    cursor: ${(props) => (props.$isBuildVersion ? 'default' : 'pointer')};

    &:hover {
      color: ${(props) =>
        props.$isBuildVersion
          ? theme.colors.textSecondary
          : theme.colors.darkGrey};
    }
  }
`;

export const SocialIcon = styled(Image)`
  &&& {
    font-size: 26px;
    transition: filter 0.2s;

    &:hover {
      filter: invert(1);
    }
  }
`;

export const LinksRow = styled(Row)`
  margin: 0 auto;
`;

export const BuildVersion = styled(Typography.Text)`
  &&& {
    display: block;
    margin-top: 20px;
    color: ${theme.colors.textSecondary};
  }
`;

export const SubscribeButton = styled(Button)`
  &&& {
    width: 32px;
    height: 32px;

    img {
      width: 10px;
      display: block;
      margin-right: -2px;
      transition: filter 0.2s;
    }

    &:hover {
      img {
        filter: invert(1);
      }
    }
  }
`;

interface AstronautImageProps extends CommonProps {
  $screens?: {
    xs?: boolean;
    sm?: boolean;
    md?: boolean;
    lg?: boolean;
    xl?: boolean;
    xxl?: boolean;
  };
}

export const AstronautImage = styled(Image)<AstronautImageProps>`
  &&& {
    opacity: 0.6;
    position: absolute;
    bottom: ${({ $screens }) =>
      $screens?.xs ? '34%' : $screens.lg ? '-110px' : '55%'};
    left: ${({ $screens }) =>
      $screens?.xs ? '44%' : $screens.lg ? '30px' : '63%'};
    width: ${({ $screens }) =>
      $screens?.xs ? '350px' : $screens.lg ? '350px' : '300px'};
    max-width: 460px;
    height: auto;
  }
`;
