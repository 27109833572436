import * as R from 'ramda';

export interface Entity {
  id: string;
  attributes: Attributes;
}

export interface Attributes {
  [key: string]: any;
}

export interface EntityContainer {
  data: Entity | Entity[];
  meta?: any;
}

const flattenEntityAttributes = (entity: Entity): Object => {
  const flattenAttributes = (attrs: Attributes): Object => {
    return R.mapObjIndexed((value: any, key: string) => {
      // Check if the value is an object and contains 'data' field
      if (R.is(Object, value) && R.has('data', value)) {
        // Handle arrays of entities separately
        if (R.is(Array, value.data)) {
          return R.map(flattenEntityAttributes, value.data);
        }
        // Handle single entity objects
        if (value.data) {
          return flattenEntityAttributes(value.data as Entity);
        }
        return null;
      } else if (R.is(Array, value)) {
        // Handle arrays which are not wrapped in an object with 'data' field
        return R.map(
          (v) => (R.is(Object, v) ? flattenAttributes(v) : v),
          value
        );
      } else if (R.is(Object, value)) {
        // Recursively flatten nested objects
        return flattenAttributes(value);
      }
      return value;
    }, attrs);
  };

  return R.isNil(entity)
    ? null
    : { id: entity.id, ...flattenAttributes(entity.attributes) };
};

export const flattenEntities = (container: EntityContainer): any => {
  if (!container) return null;

  const { data } = container;
  let formattedData;

  if (Array.isArray(data)) {
    formattedData = { data: R.map(flattenEntityAttributes, data) };
  } else {
    formattedData = { data: flattenEntityAttributes(data) };
  }

  if (container.meta?.pagination) {
    formattedData = {
      ...formattedData,
      pagination: container.meta.pagination,
    };
  }

  return formattedData;
};
