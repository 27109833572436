import { memo, useState } from 'react';
import {
  Modal,
  Form,
  Button,
  Row,
  message,
  Flex,
  Typography,
  Grid,
  List,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import useSWR from 'swr';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/nextjs';

import { Merchant } from 'types/opp';

import KYCForm from 'components/Auth/Onboarding/Investor/KYC';

import { uploadKycDocuments } from 'containers/Auth/Onboarding/Investor/utils';
import useUserData from 'hooks/useUserData';
import { fetchGetJSON } from 'utils/apiHelpers';
import { getKycDisapprovalReason } from '../statusHelpers';
import { merchantEndpoint } from 'utils/oppHelpers';

import characterImage from 'assets/images/characters/diversification.png';

import theme from 'styles/theme';
import { DisapprovalsList, DisapprovalsListTitle, Character } from './styles';

export interface KYCModalProps {
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
}

const KYCModal = ({ isOpen, setIsOpen }: KYCModalProps) => {
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [kycComplete, setKycComplete] = useState(false);

  const { userData } = useUserData();
  const { data: merchant, mutate } = useSWR<Merchant>(
    merchantEndpoint(userData.OPP?.merchantId)
  );

  /**
   * Handles form submission
   * @param values - Form values from the payment form
   */
  const handleSubmit = async (values: unknown) => {
    try {
      await form.validateFields();

      setLoading(true);

      const responses = await uploadKycDocuments({
        form,
        merchantId: userData.OPP.merchantId,
      });

      if (responses.some((response) => response.error)) {
        const error = responses.find((response) => response.error)?.error;
        message.error(error);
        Sentry.captureException(error, {
          user: { id: userData?.id },
          tags: {
            action: 'dashboard_kyc_documents_submit',
          },
        });
        setLoading(false);
        return;
      }

      await mutate();

      posthog.capture('dashboard_kyc_documents_submitted');

      setKycComplete(true);
      setLoading(false);
    } catch (error) {
      Sentry.captureException(error, {
        user: { id: userData?.id },
        tags: {
          action: 'dashboard_kyc_documents_submit',
        },
      });
      setLoading(false);
      message.error(
        'There was an error submitting your documents. Please try again'
      );
    }
  };

  const isMobile = screens.md === false;

  return (
    <Modal
      open={isOpen}
      footer={null}
      width={800}
      title={null}
      onCancel={() => setIsOpen(false)}
      closeIcon={null}
      styles={{ body: { padding: isMobile ? '30px' : '40px' } }}
    >
      {!kycComplete ? (
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          {merchant?.contacts[0].disapprovals.length > 0 && (
            <DisapprovalsList
              itemLayout="horizontal"
              header={
                <DisapprovalsListTitle level={5}>
                  <ExclamationCircleOutlined style={{ marginRight: 10 }} />
                  Issues to resolve
                </DisapprovalsListTitle>
              }
              dataSource={merchant?.contacts[0].disapprovals}
              renderItem={(
                item: Merchant['contacts'][0]['disapprovals'][0]
              ) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={null}
                    title={
                      <span>
                        {getKycDisapprovalReason(item.name).title}{' '}
                        <span
                          style={{
                            fontWeight: 400,
                            color: 'rgba(0, 0, 0, 0.45)',
                          }}
                        >
                          - {moment.unix(item.created).format('D MMM')}
                        </span>
                      </span>
                    }
                    description={getKycDisapprovalReason(item.name).description}
                  />
                </List.Item>
              )}
            />
          )}
          <KYCForm form={form} />

          <Row justify="space-between" style={{ marginTop: 20 }}>
            <Button onClick={() => setIsOpen(false)}>Cancel</Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit documents
            </Button>
          </Row>
        </Form>
      ) : (
        <Flex
          justify="space-around"
          align="center"
          vertical={screens.lg === false}
          gap={isMobile ? 10 : 50}
          style={{ padding: isMobile ? '0' : '30px' }}
        >
          <Character
            src={characterImage}
            alt=""
            priority
            $isMobile={isMobile}
          />
          <Flex gap={20} align="center" justify="center" vertical>
            <Typography.Title
              level={isMobile ? 3 : 2}
              style={{ marginBottom: 0, textAlign: 'center' }}
            >
              Documents submitted
            </Typography.Title>
            <Typography.Text
              type="secondary"
              style={{ maxWidth: 400, textAlign: 'center' }}
            >
              Thank you for submitting your documents! We will review them
              shortly and notify you of any updates via email.
            </Typography.Text>
            <Button type="primary" onClick={() => setIsOpen(false)}>
              Continue
            </Button>
          </Flex>
        </Flex>
      )}
    </Modal>
  );
};

export default memo(KYCModal);
