import { gql } from '@apollo/client';

export const GET_VERTICALS_QUERY = gql`
  query {
    verticals(pagination: { limit: 100 }, sort: "name:asc") {
      data {
        id
        attributes {
          name
        }
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
`;
