import { gql } from '@apollo/client';

export const NOTIFICATIONS_QUERY = gql`
  query getNotifications(
    $pagination: PaginationArg
    $filters: NotificationFiltersInput
  ) {
    notifications(
      sort: "createdAt:desc"
      pagination: $pagination
      filters: $filters
    ) {
      meta {
        pagination {
          total
        }
      }
      data {
        id
        attributes {
          type
          createdAt
          subject
          message
          seen
        }
      }
    }
  }
`;

export const UPDATE_NOTIFICATIONS_QUERY = gql`
  mutation markNotificationsSeen {
    markNotificationsSeen {
      count
    }
  }
`;

export const CREATE_NOTIFICATION_QUERY = gql`
  mutation createNotification($to: ID!, $type: String!) {
    createNotification(data: { to: $to, type: $type }) {
      data {
        id
      }
    }
  }
`;

export const DELETE_NOTIFICATION_QUERY = gql`
  mutation deleteNotification($id: ID!) {
    deleteNotification(id: $id) {
      data {
        id
      }
    }
  }
`;
