import styled from 'styled-components';
import { Button, Steps } from 'antd';
import { WarningFilled } from '@ant-design/icons';

import theme from 'styles/theme';

interface CommonProps {
  $isMobile?: boolean;
  $isDarkBackground?: boolean;
  $isMenuOpen?: boolean;
}

export const OnboardingChecklistIcon = styled(WarningFilled)<CommonProps>`
  &&& {
    position: relative;
    font-size: 22px;
    color: ${theme.colors.warning};
    opacity: ${(props) => (props.$isMenuOpen ? 0 : 1)};
    pointer-events: ${(props) => (props.$isMenuOpen ? 'none' : 'auto')};
    transition:
      opacity 0.3s ease-out ${(props) => (props.$isMenuOpen ? '0s' : '0.7s')},
      transform 0.1s ease-out;
    animation: pulse 2s infinite;

    @keyframes pulse {
      0% {
        filter: drop-shadow(0 0 2px ${theme.colors.warning}40);
      }
      50% {
        filter: drop-shadow(0 0 8px ${theme.colors.warning}60);
      }
      100% {
        filter: drop-shadow(0 0 2px ${theme.colors.warning}40);
      }
    }

    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const OnboardingChecklistWrapper = styled.div<CommonProps>`
  &&& {
    position: relative;
    padding: ${(props) => (props.$isMobile ? '20px 25px' : '30px 32px')};
  }
`;

export const OnboardingChecklistSteps = styled(Steps)<CommonProps>`
  &&& {
    margin-bottom: 0;

    .ant-timeline-item-head {
    }

    .ant-timeline-item-last {
      padding-bottom: 0;

      .ant-timeline-item-content {
        min-height: 0;
      }
    }

    .ant-timeline-item-tail {
      /* border-inline-start: 1px solid ${theme.colors.coolGrey}30; */
    }
  }
`;

interface OnboardingChecklistStepsCtaProps extends CommonProps {}

export const OnboardingChecklistStepsCta = styled(Button).attrs({
  block: true,
})<OnboardingChecklistStepsCtaProps>`
  &&& {
    margin-top: 10px;
  }
`;
