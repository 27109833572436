import { createContext, useContext, useState, ReactNode } from 'react';

// Define the type for the context state
interface LogoContextType {
  isDarkBackground: boolean;
  setIsDarkBackground: (isDark: boolean) => void;
  isSolidBackground: boolean;
  setIsSolidBackground: (isSolid: boolean) => void;
}

// Create the context with a default value of null
const LogoContext = createContext<LogoContextType | undefined>(undefined);

// Create the provider component with props type for children
export function LogoProvider({ children }: { children: ReactNode }) {
  const [isDarkBackground, setIsDarkBackground] = useState(true);
  const [isSolidBackground, setIsSolidBackground] = useState(false);

  return (
    <LogoContext.Provider
      value={{
        isDarkBackground,
        setIsDarkBackground,
        isSolidBackground,
        setIsSolidBackground,
      }}
    >
      {children}
    </LogoContext.Provider>
  );
}

// Create a custom hook to use the LogoContext
export function useLogoState() {
  const context = useContext(LogoContext);
  if (!context) {
    throw new Error('useLogoState must be used within a LogoProvider');
  }
  return context;
}
