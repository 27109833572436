import { gql } from '@apollo/client';

export const GET_PROJECT_RATING_QUERY = gql`
  query getProjectRatings($projectId: ID!) {
    getProjectRatings(projectId: $projectId) {
      totalRatings
      averageTeamValue
      averageBusinessModelValue
      averageMarketOpportunityValue
      averageProductServiceValue
      averageTractionValue
      averageFinancialsValue
    }
  }
`;

export const GET_RATINGS_FOR_PROJECT_QUERY = gql`
  query getRatingsForProject($start: Int, $limit: Int, $projectId: ID!) {
    ratings(
      pagination: { start: $start, limit: $limit }
      filters: { project: { id: { eq: $projectId } } }
    ) {
      data {
        id
        attributes {
          teamValue
          businessModelValue
          marketOpportunityValue
          productServiceValue
          tractionValue
          financialsValue
          feedback {
            createdOn
            rating
            section
            content
            audio {
              data {
                attributes {
                  url
                }
              }
            }
          }
          user {
            data {
              id
              attributes {
                accountDetails {
                  firstName
                  lastName
                }
                avatar {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_RATING_FOR_PROJECT_QUERY = gql`
  query getUserRatingForProject($userId: ID!, $projectId: ID!) {
    ratings(
      filters: {
        and: [
          { user: { id: { eq: $userId } } }
          { project: { id: { eq: $projectId } } }
        ]
      }
    ) {
      data {
        id
        attributes {
          teamValue
          businessModelValue
          marketOpportunityValue
          productServiceValue
          tractionValue
          financialsValue
          feedback {
            createdOn
            rating
            section
            content
            audio {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_RATINGS_COUNT_QUERY = gql`
  query getUserRatingsCount($userId: ID!) {
    ratingsConnection(where: { user: $userId }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_USER_RATINGS_QUERY = gql`
  query getUserRatings($start: Int, $limit: Int, $userId: ID!) {
    ratings(start: $start, limit: $limit, where: { user: $userId }) {
      id
      teamValue
      businessModelValue
      marketOpportunityValue
      productServiceValue
      tractionValue
      financialsValue
      feedback {
        createdOn
        rating
        section
        content
        audio {
          data {
            attributes {
              url
            }
          }
        }
      }
      project {
        data {
          id
          attributes {
            name
            slug
          }
        }
      }
    }
  }
`;

export const CREATE_RATING_QUERY = gql`
  mutation createRating(
    $project: ID!
    $user: ID!
    $teamValue: Int
    $businessModelValue: Int
    $marketOpportunityValue: Int
    $productServiceValue: Int
    $tractionValue: Int
    $financialsValue: Int
    $feedback: [ComponentRatingFeedbackInput]
  ) {
    createRating(
      data: {
        project: $project
        user: $user
        teamValue: $teamValue
        businessModelValue: $businessModelValue
        marketOpportunityValue: $marketOpportunityValue
        productServiceValue: $productServiceValue
        tractionValue: $tractionValue
        financialsValue: $financialsValue
        feedback: $feedback
      }
    ) {
      data {
        id
        attributes {
          project {
            data {
              id
              attributes {
                slug
                name
                owner {
                  data {
                    id
                    attributes {
                      accountDetails {
                        firstName
                        lastName
                      }
                    }
                  }
                }
              }
            }
          }
          teamValue
          businessModelValue
          marketOpportunityValue
          productServiceValue
          tractionValue
          financialsValue
          feedback {
            createdOn
            rating
            section
            content
            audio {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_RATING_QUERY = gql`
  mutation updateRating(
    $id: ID!
    $teamValue: Int
    $businessModelValue: Int
    $marketOpportunityValue: Int
    $productServiceValue: Int
    $tractionValue: Int
    $financialsValue: Int
    $feedback: [ComponentRatingFeedbackInput]
  ) {
    updateRating(
      id: $id
      data: {
        teamValue: $teamValue
        businessModelValue: $businessModelValue
        marketOpportunityValue: $marketOpportunityValue
        productServiceValue: $productServiceValue
        tractionValue: $tractionValue
        financialsValue: $financialsValue
        feedback: $feedback
      }
    ) {
      data {
        id
        attributes {
          project {
            data {
              id
              attributes {
                slug
                name
                owner {
                  data {
                    id
                    attributes {
                      accountDetails {
                        firstName
                        lastName
                      }
                    }
                  }
                }
              }
            }
          }
          teamValue
          businessModelValue
          marketOpportunityValue
          productServiceValue
          tractionValue
          financialsValue
          feedback {
            createdOn
            rating
            section
            content
            audio {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
