import { gql } from '@apollo/client';

import { getUpcomingInvestmentFragment } from 'queries/upcomingInvestment';

export const getInvestmentFragment = gql`
  ${getUpcomingInvestmentFragment}
  fragment GetInvestmentFields on Investment {
    upcomingInvestment {
      data {
        id
        attributes {
          ...GetUpcomingInvestmentFields
        }
      }
    }
    dateCreated
    status
    hasReflectionPeriod
    amount
    confirmationDocuments {
      data {
        attributes {
          url
          name
        }
      }
    }
    declineReason
    investments {
      id
      assetClass
      project {
        data {
          id
          attributes {
            OPP {
              merchantId
              profileId
              projectMerchantId
              projectMerchantProfileId
            }
            founderProfiles {
              name
              position
              linkedInUrl
              avatar {
                data {
                  attributes {
                    url
                    width
                    height
                  }
                }
              }
            }
            icon {
              data {
                attributes {
                  url
                  width
                  height
                }
              }
            }
            logo {
              data {
                attributes {
                  url
                  width
                  height
                }
              }
            }
            name
            slug
            description
            fundingTarget
            fundingCommitted
            distributionModel
            incorporationCountry
            leadInvestor
            leadInvestorUrl
            verticals {
              data {
                id
                attributes {
                  name
                }
              }
            }
          }
        }
      }
      amount
      type
      sharePrice
      sharesAcquired
      valuationCap
      discount
      chargeId
    }
  }
`;

export const GET_INVESTMENTS_QUERY = gql`
  ${getInvestmentFragment}
  query getInvestments(
    $filters: InvestmentFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    investments(pagination: $pagination, filters: $filters, sort: $sort) {
      data {
        id
        attributes {
          ...GetInvestmentFields
        }
      }
      meta {
        pagination {
          page
          pageSize
          total
          pageCount
        }
      }
    }
  }
`;

export const GET_INVESTMENTS_COUNT_AND_SUM_QUERY = gql`
  query getInvestmentsCountAndSum($userId: ID) {
    getInvestmentsCountAndSum(userId: $userId) {
      invested
      holdings
      value
      irr
      roi
      message
    }
  }
`;

export const GET_SYNDICATE_QTRLY_AND_INVEST_SUM_QUERY = gql`
  query getSyndicateQuarterlySumAndInvestmentSum($syndicateLead: ID) {
    getSyndicateQuarterlySumAndInvestmentSum(syndicateLead: $syndicateLead) {
      investmentSum
      quarterlySum
    }
  }
`;

export const GET_INVESTMENT_QUERY = gql`
  ${getInvestmentFragment}
  query getInvestment($id: ID) {
    investment(id: $id) {
      data {
        id
        attributes {
          ...GetInvestmentFields
        }
      }
    }
  }
`;

export const CREATE_INVESTMENT_QUERY = gql`
  ${getInvestmentFragment}
  mutation createInvestment(
    $user: ID!
    $upcomingInvestment: ID!
    $dateCreated: DateTime!
    $status: ENUM_INVESTMENT_STATUS!
    $hasReflectionPeriod: Boolean
    $investments: [ComponentInvestmentItemInput]
    $amount: Float
    $declineReason: String
  ) {
    createInvestment(
      data: {
        user: $user
        upcomingInvestment: $upcomingInvestment
        dateCreated: $dateCreated
        status: $status
        hasReflectionPeriod: $hasReflectionPeriod
        investments: $investments
        amount: $amount
        declineReason: $declineReason
      }
    ) {
      data {
        id
        attributes {
          ...GetInvestmentFields
        }
      }
    }
  }
`;

export const UPDATE_INVESTMENT_QUERY = gql`
  ${getInvestmentFragment}
  mutation updateInvestment($id: ID!, $status: ENUM_INVESTMENT_STATUS!) {
    updateInvestment(id: $id, data: { status: $status }) {
      data {
        id
        attributes {
          ...GetInvestmentFields
        }
      }
    }
  }
`;
