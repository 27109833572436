import styled from 'styled-components';
import { Typography } from 'antd';

import theme from 'styles/theme';

export const ReturnsProjectorChartWrapper = styled.div`
  &&& {
    padding: 20px 15px 10px 20px;
    background-color: ${theme.colors.white};
    border-radius: 8px;
    height: 300px;
    box-shadow: 0px 5px 8px 0px ${theme.colors.black}10;
    border: 1px solid #f0f0f0;
  }
`;

export const LineChartTooltipWrapper = styled.div`
  background-color: ${theme.colors.primarySoftest};
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.primaryHalf};
`;

export const LineChartTooltipTitle = styled(Typography.Title)`
  &&& {
    margin-bottom: 0px;
  }
`;

export const LineChartTooltipData = styled.div``;

export const LineChartTooltipDataItemAsset = styled(Typography.Text)`
  &&& {
    font-size: 14px;
    margin-right: 5px;
  }
`;
export const LineChartTooltipDataItemValue = styled(Typography.Text)`
  &&& {
    font-size: 14px;
    font-weight: bold;
  }
`;

export const LineChartTooltipDataItem = styled.div<{
  $color?: string;
}>`
  ${LineChartTooltipDataItemValue} {
    color: ${(props) => props.$color};
  }
`;
