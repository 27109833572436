import moment from 'moment';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

const generatePDF = async (name: string, id: string): Promise<File> => {
  const url = '/documents/Shuttle-Investment-Terms-And-Conditions.pdf';
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  const { width, height } = firstPage.getSize();

  firstPage.drawText(name.toUpperCase(), {
    x: 92,
    y: height - 435,
    size: 11,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(id, {
    x: width / 2 + 145,
    y: height - 435,
    size: 11,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  const pdfBytes = await pdfDoc.save();

  const filename = `Investment-Terms-${name}-${moment().format('DD-MM-YYYY')}.pdf`;
  const file = new File([pdfBytes], filename, { type: 'application/pdf' });

  return file;
};

export default generatePDF;
