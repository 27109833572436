import { gql } from '@apollo/client';

export const COOKIE_BANNER_QUERY = gql`
  {
    cookiePolicyModal {
      data {
        attributes {
          copy
        }
      }
    }
  }
`;

export const HOME_PAGE_QUERY = gql`
  {
    homePage {
      data {
        attributes {
          copy
        }
      }
    }
  }
`;

export const REGISTER_PAGE_QUERY = gql`
  {
    registerPage {
      data {
        attributes {
          copy
        }
      }
    }
  }
`;

export const DASHBOARD_PAGE_QUERY = gql`
  {
    dashboardPage {
      data {
        attributes {
          copy
        }
      }
    }
  }
`;

export const OPPORTUNITIES_PAGE_QUERY = gql`
  {
    opportunitiesPage {
      data {
        attributes {
          copy
        }
      }
    }
  }
`;

export const RESOURCES_PAGE_QUERY = gql`
  {
    resourcesPage {
      data {
        attributes {
          copy
        }
      }
    }
  }
`;

export const TERMS_OF_USE_PAGE_QUERY = gql`
  {
    termsOfUsePage {
      data {
        attributes {
          content
        }
      }
    }
  }
`;

export const PRIVACY_POLICY_QUERY = gql`
  {
    privacyPolicyPage {
      data {
        attributes {
          content
        }
      }
    }
  }
`;

export const RISK_WARNING_QUERY = gql`
  {
    riskWarningPage {
      data {
        attributes {
          content
        }
      }
    }
  }
`;

export const INVESTEE_TERMS_PAGE_QUERY = gql`
  {
    investeeTermsPage {
      data {
        attributes {
          content
        }
      }
    }
  }
`;

export const CODE_OF_CONDUCT_PAGE_QUERY = gql`
  {
    codeOfConductPage {
      data {
        attributes {
          content
        }
      }
    }
  }
`;

export const COMPLAINTS_PAGE_QUERY = gql`
  {
    complaintsPage {
      data {
        attributes {
          content
          copy
        }
      }
    }
  }
`;
