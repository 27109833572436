import * as R from 'ramda';

import { NOTIFICATIONS_QUERY } from 'queries';

export const updateNotificationCache = (
  cache,
  notificationId,
  session,
  limit,
  setNotificationsOpen
) => {
  const variables = {
    pagination: {
      start: 0,
      limit,
    },
    filters: {
      to: { id: { eq: session?.user.id } },
    },
  };

  const cachedNotifications: any = cache.readQuery({
    query: NOTIFICATIONS_QUERY,
    variables,
  });

  if (!cachedNotifications?.notifications) return;

  cache.writeQuery({
    query: NOTIFICATIONS_QUERY,
    variables,
    data: {
      notifications: {
        __typename: 'NotificationEntityResponseCollection',
        meta: {
          pagination: {
            total: cachedNotifications?.notifications.meta.pagination.total - 1,
          },
        },
        data: R.reject(
          R.propEq(notificationId, 'id'),
          cachedNotifications?.notifications.data
        ),
      },
    },
  });

  if (cachedNotifications?.notifications.meta.pagination.total - 1 === 0) {
    setNotificationsOpen(false);
  }
};
