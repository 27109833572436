import { gql } from '@apollo/client';

export const UPLOAD_QUERY = gql`
  mutation uploadFile(
    $refId: ID
    $ref: String
    $field: String
    $info: FileInfoInput
    $file: Upload!
  ) {
    upload(refId: $refId, ref: $ref, field: $field, file: $file, info: $info) {
      data {
        id
        attributes {
          url
          width
          height
        }
      }
    }
  }
`;
