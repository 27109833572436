import { useEffect, useState } from 'react';
import { Grid, Flex, Space } from 'antd';
import Link from 'next/link';
import { posthog } from 'posthog-js';
import { motion } from 'framer-motion';
import { signOut, useSession } from 'next-auth/react';

import { useMenu } from 'context/MenuContext';

import {
  MenuContainer,
  MenuBackground,
  MenuSupergraphicWrapper,
  MenuSupergraphic,
  MenuLinks,
  MenuLink,
  MenuLinkNoHref,
  MenuFooter,
  MenuSocialIcon,
  MenuFooterDescription,
} from './styles';

import supergraphicImage from 'assets/images/menu-supergraphic.png';

const handleSocialClick = (platform: string) => {
  posthog.capture('social_link_clicked', {
    platform,
  });
};

interface BurgerMenuProps {
  isAuthFlow: boolean;
}

const BurgerMenu = ({ isAuthFlow }: BurgerMenuProps) => {
  const screens = Grid.useBreakpoint();
  const { data: session } = useSession();
  const { isMenuOpen, setIsMenuOpen } = useMenu();

  const [shouldMoveUp, setShouldMoveUp] = useState<boolean>(true);
  const [viewport, setViewport] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setViewport({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    handleResize(); // Set initial size

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Using the smaller of width/height to ensure it stays proportional
  const cutSize = Math.min(viewport.width, viewport.height) * 0.13;

  // Calculate the extra space needed to cover the diagonal gap
  const extraSpace = cutSize * Math.SQRT2; // Math.SQRT2 ≈ 1.414, diagonal of a square

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isMenuOpen) {
      setShouldMoveUp(false);
    } else {
      timeoutId = setTimeout(() => {
        setShouldMoveUp(true);
      }, 300);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isMenuOpen]);

  const isMobile = screens.md === false;
  const isAuthenticated = !!session && !isAuthFlow;

  const renderSocialLink = (src, url, platform) => (
    <Link
      href={url}
      target="_blank"
      rel="noreferrer"
      onClick={() => handleSocialClick(platform)}
    >
      <MenuSocialIcon
        src={src}
        alt="Logo"
        width={15}
        height={15}
        $isOpen={isMenuOpen}
      />
    </Link>
  );

  const renderLink = (url, copy) => (
    <MenuLink
      href={url}
      onClick={() => setIsMenuOpen(false)}
      $isOpen={isMenuOpen}
      $shouldMoveUp={shouldMoveUp}
      $isMobile={isMobile}
      $isAuthenticated={isAuthenticated}
    >
      {copy}
    </MenuLink>
  );

  const basePath = `M${viewport.width + extraSpace} 0 
    L${viewport.width + extraSpace} ${viewport.height + extraSpace} 
    L0 ${viewport.height + extraSpace} 
    L-${extraSpace} ${viewport.height} 
    L-${extraSpace} 0 Z`;

  const pathVariants = {
    closed: {
      d: basePath,
      transform: `translate(${viewport.width + 150}px, -${viewport.height + 150}px)`,
      transition: { duration: 1, ease: [0.76, 0, 0.24, 1] },
    },
    open: {
      d: basePath,
      transform: 'translate(0px, 0px)',
      transition: { duration: 1, ease: [0.25, 1, 0.5, 1] },
    },
  };

  const menuLinks = isAuthenticated ? (
    <>
      {renderLink('/dashboard', 'Dashboard')}
      {renderLink('/opportunities', 'Opportunities')}
      {renderLink('/portfolio', 'Portfolio')}
      {renderLink('/account', 'Account')}
      <MenuLinkNoHref
        $isOpen={isMenuOpen}
        $shouldMoveUp={shouldMoveUp}
        $isMobile={isMobile}
        $isAuthenticated={isAuthenticated}
        onClick={async () => {
          await signOut({
            callbackUrl: `${process.env.NEXTAUTH_URL}`,
          });
          posthog.capture('sign_out');
          posthog.reset();
        }}
      >
        Sign out
      </MenuLinkNoHref>
    </>
  ) : (
    <>
      {renderLink('/insights/what-is-shuttle', 'What is Shuttle?')}
      {renderLink('/insights/who-is-shuttle-for', 'Who is it for?')}
      {renderLink('/insights/how-does-shuttle-work', 'How does it work?')}
      {renderLink('/auth/login', 'Sign in')}
    </>
  );

  return (
    <MenuContainer $isOpen={isMenuOpen} $isMobile={isMobile}>
      <svg width={viewport.width} height={viewport.height}>
        <defs>
          <clipPath id="polygonClipPath">
            <motion.path
              variants={pathVariants}
              initial="closed"
              animate={isMenuOpen ? 'open' : 'closed'}
              d={pathVariants.closed.d}
            />
          </clipPath>
        </defs>
      </svg>
      <MenuBackground $isOpen={isMenuOpen} />
      <MenuSupergraphicWrapper $isOpen={isMenuOpen}>
        <MenuSupergraphic
          $isOpen={isMenuOpen}
          $isMobile={isMobile}
          src={supergraphicImage}
          alt=""
          quality={100}
        />
      </MenuSupergraphicWrapper>
      <MenuLinks
        $isOpen={isMenuOpen}
        $shouldMoveUp={shouldMoveUp}
        $isMobile={isMobile}
      >
        {menuLinks}
      </MenuLinks>
      <MenuFooter
        justify="start"
        align="bottom"
        $isOpen={isMenuOpen}
        $isMobile={isMobile}
      >
        <Flex vertical gap={20}>
          <Space size={25}>
            {renderSocialLink(
              '/images/footer/linkedin.svg',
              'https://www.linkedin.com/company/shuttleclub',
              'linkedin'
            )}
            {renderSocialLink(
              '/images/footer/instragram.svg',
              'https://www.instagram.com/club.shuttle',
              'instagram'
            )}
            {renderSocialLink(
              '/images/footer/twitter.svg',
              'https://x.com/club_shuttle',
              'twitter'
            )}
          </Space>
          <MenuFooterDescription type="secondary" $isMobile={isMobile}>
            PitchedIt Ltd t/a Shuttle Finance is regulated by the Central Bank
            of Ireland. Registered with the Companies Registration Office in
            Ireland. Company number 697037.
          </MenuFooterDescription>
        </Flex>
      </MenuFooter>
    </MenuContainer>
  );
};

export default BurgerMenu;
