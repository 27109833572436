import { createContext, useContext, useState, ReactNode } from 'react';

type LoaderContextType = {
  isLoaderComplete: boolean;
  setIsLoaderComplete: React.Dispatch<React.SetStateAction<boolean>>;
};

const LoaderContext = createContext<LoaderContextType | undefined>(undefined);

type LoaderProviderProps = {
  children: ReactNode;
};

export function LoaderProvider({ children }: LoaderProviderProps) {
  const [isLoaderComplete, setIsLoaderComplete] = useState<boolean>(false);

  return (
    <LoaderContext.Provider value={{ isLoaderComplete, setIsLoaderComplete }}>
      {children}
    </LoaderContext.Provider>
  );
}

export const useLoader = (): LoaderContextType => {
  const context = useContext(LoaderContext);
  if (context === undefined) {
    throw new Error('useLoader must be used within a LoaderProvider');
  }
  return context;
};
