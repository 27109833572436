import { memo, useState } from 'react';
import {
  Button,
  message,
  Typography,
  Flex,
  Grid,
  Alert,
  Descriptions,
  Tooltip,
  Popconfirm,
} from 'antd';
import { CopyOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import useSWR from 'swr';
import copy from 'copy-to-clipboard';
import { useMutation } from '@apollo/client';
import { useSWRConfig } from 'swr';

import { Transaction } from 'types/opp';

import { UPDATE_ME_QUERY } from 'queries/user';

import useUserData from 'hooks/useUserData';
import { formatAmountForPIL, transactionEndpoint } from 'utils/oppHelpers';
import {
  getPaymentStatus,
  getQuarterlyInvestmentStatus,
  PaymentStatus,
} from 'utils/oppHelpers';
import { fetchDeleteJSON } from 'utils/apiHelpers';

import theme from 'styles/theme';
import { currencyFormatter } from 'utils/strings';
import { updateUser } from 'components/Profile/Edit/updateCache';

export interface PaymentInstructionsProps {
  setIsOpen(isOpen: boolean): void;
  paymentType: 'manual' | 'renewal';
  setPaymentComplete: (paymentComplete: boolean) => void;
}

const PaymentInstructions = ({
  setIsOpen,
  paymentType,
  setPaymentComplete,
}: PaymentInstructionsProps) => {
  const screens = Grid.useBreakpoint();

  const { userData } = useUserData();

  const [cancelLoading, setCancelLoading] = useState(false);

  const { mutate: globalMutate } = useSWRConfig();

  const { data: annualTransaction } = useSWR<Transaction>(
    transactionEndpoint(userData.OPP?.annualFee?.transactionId)
  );
  const { data: quarterlyTransaction } = useSWR<Transaction>(
    transactionEndpoint(userData.investorDetails?.transactionId)
  );
  const { data: manualTransaction } = useSWR<Transaction>(
    transactionEndpoint(userData.OPP?.manualPayment?.transactionId)
  );

  const [saveUser] = useMutation(UPDATE_ME_QUERY, {
    update: async (cache, { data }) => {
      updateUser(cache, data, userData.id);
    },
  });

  const handleCopy = (text: string, itemName: string): void => {
    copy(text);
    message.destroy();
    message.success(`${itemName} copied to clipboard`);
  };

  const copyableDescriptionItem = (label: string, value: string) => {
    return (
      <Descriptions.Item
        label={label}
        labelStyle={{ fontWeight: 600, color: theme.colors.labelText }}
      >
        <Flex justify="space-between" align="center">
          {value}
          <Tooltip title="Copy">
            <CopyOutlined
              onClick={() => handleCopy(value, label)}
              style={{ cursor: 'pointer' }}
            />
          </Tooltip>
        </Flex>
      </Descriptions.Item>
    );
  };

  const getTransactionAmount = (): string => {
    if (paymentType === 'manual') {
      return manualTransaction?.amount
        ? currencyFormatter(formatAmountForPIL(manualTransaction?.amount))
        : 'Loading...';
    }

    const annualPaymentStatus = getPaymentStatus(userData?.OPP?.annualFee);
    const quarterlyPaymentStatus = getQuarterlyInvestmentStatus(
      userData?.investorDetails
    );

    return (
      (annualPaymentStatus === PaymentStatus.Created &&
        annualTransaction?.amount &&
        currencyFormatter(formatAmountForPIL(annualTransaction?.amount))) ||
      (quarterlyPaymentStatus === PaymentStatus.Created &&
        quarterlyTransaction?.amount &&
        currencyFormatter(formatAmountForPIL(quarterlyTransaction?.amount))) ||
      'Loading...'
    );
  };

  const handleCancelTransaction = async () => {
    setCancelLoading(true);

    let transactionId;
    switch (paymentType) {
      case 'manual':
        transactionId = userData.OPP?.manualPayment?.transactionId;
        break;
      case 'renewal':
        transactionId = userData.OPP?.annualFee?.transactionId;
        break;
    }

    try {
      const transaction = await fetchDeleteJSON(
        `/api/psp/transactions/${transactionId}`
      );

      if (transaction.error) {
        message.error(
          'Unable to cancel transaction. Please try again later or contact support.'
        );
        setCancelLoading(false);
        return;
      }
    } catch (error) {
      message.error(
        'Unable to cancel transaction. Please try again later or contact support.'
      );
      setCancelLoading(false);
      return;
    }

    const updateData =
      paymentType === 'manual'
        ? {
            OPP: {
              id: userData.OPP.id,
              manualPayment: null,
            },
          }
        : {
            OPP: {
              id: userData.OPP.id,
              annualFee: {
                transactionId: null,
                status: null,
              },
              paymentInstructions: null,
            },
            investorDetails: {
              id: userData.investorDetails.id,
              transactionId: null,
              transactionStatus: null,
            },
          };

    await saveUser({
      variables: {
        id: userData.id,
        ...updateData,
      },
    });

    globalMutate(
      (key) =>
        typeof key === 'string' &&
        key.includes(
          `/api/psp/transactions?filter[merchant]=${userData.OPP.merchantId}`
        ),
      undefined,
      { revalidate: true }
    );

    message.success('Transaction cancelled');
    setCancelLoading(false);
    setPaymentComplete(false);
  };

  const paymentInstructions =
    paymentType === 'manual'
      ? {
          accountName: userData.OPP?.manualPayment?.accountName,
          iban: userData.OPP?.manualPayment?.iban,
          bic: userData.OPP?.manualPayment?.bic,
          reference: userData.OPP?.manualPayment?.reference,
        }
      : userData.OPP?.paymentInstructions;

  const isMobile = screens.md === false;

  return (
    <Flex
      justify="space-around"
      align="center"
      vertical={true}
      gap={isMobile ? 20 : 30}
    >
      <Typography.Title
        level={isMobile ? 3 : 2}
        style={{ marginBottom: 0, textAlign: 'center' }}
      >
        Payment Instructions
      </Typography.Title>
      <Typography.Text
        style={{
          maxWidth: 550,
          textAlign: 'center',
          color: theme.colors.bodyText,
        }}
      >
        Please now make a payment from your bank to the account details provided
        below. Transfers are settled same day if transferred before 2pm.
      </Typography.Text>
      <Alert
        description="Please note that the reference below is critical and must be included when making your payment. If the reference is not included, your payment will be returned."
        type="warning"
        showIcon
      />
      <Descriptions
        column={1}
        bordered
        style={{ width: '100%' }}
        layout={isMobile ? 'vertical' : 'horizontal'}
      >
        <Descriptions.Item
          label={
            <Typography.Text
              style={{
                fontWeight: 600,
                color: theme.colors.labelText,
                marginBottom: 0,
              }}
            >
              Reference{' '}
              <Tooltip
                title="It is critical you include this reference when making your payment"
                overlayInnerStyle={{ padding: '15px 20px' }}
              >
                <ExclamationCircleFilled
                  style={{ marginLeft: 5, color: theme.colors.darkTeal }}
                />
              </Tooltip>
            </Typography.Text>
          }
          labelStyle={{ fontWeight: 600, color: theme.colors.labelText }}
        >
          <Alert
            style={{ width: '100%' }}
            description={
              <Flex justify="space-between" align="center">
                <Typography.Title level={2} style={{ marginBottom: 0 }}>
                  {paymentInstructions?.reference}
                </Typography.Title>
                <Tooltip title="Copy">
                  <CopyOutlined
                    onClick={() =>
                      handleCopy(
                        paymentInstructions?.reference,
                        'Payment reference'
                      )
                    }
                    style={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              </Flex>
            }
            type="info"
          />
        </Descriptions.Item>
        {copyableDescriptionItem('Amount', getTransactionAmount())}
        {copyableDescriptionItem(
          'Bank Account Name',
          paymentInstructions?.accountName
        )}
        {copyableDescriptionItem('IBAN', paymentInstructions?.iban)}
        {copyableDescriptionItem('BIC', paymentInstructions?.bic)}
      </Descriptions>
      <Flex justify="space-between" style={{ width: '100%' }}>
        <Popconfirm
          icon={
            <ExclamationCircleFilled style={{ color: theme.colors.error }} />
          }
          title="Cancel transaction"
          description={
            <Typography.Paragraph
              style={{ maxWidth: 320, color: theme.colors.bodyText }}
            >
              Are you sure you want to cancel this transaction? Any funds
              you&apos;ve already transferred will be automatically refunded.
            </Typography.Paragraph>
          }
          onConfirm={handleCancelTransaction}
          okText="Yes"
          cancelText="No"
          okButtonProps={{
            loading: cancelLoading,
            size: 'middle',
            danger: true,
            style: { width: 150 },
          }}
          cancelButtonProps={{
            size: 'middle',
            style: { width: 150 },
          }}
          overlayInnerStyle={{ padding: '25px 20px' }}
        >
          <Button danger>Cancel transaction</Button>
        </Popconfirm>
        <Button type="primary" onClick={() => setIsOpen(false)}>
          I&apos;ve done this
        </Button>
      </Flex>
    </Flex>
  );
};

export default memo(PaymentInstructions);
