import { useState } from 'react';
import { Col, Row, Tooltip, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import formatDistance from 'date-fns/formatDistance';
import { useSession } from 'next-auth/react';
import { useMutation } from '@apollo/client';

import { DELETE_NOTIFICATION_QUERY } from 'queries';

import { NotificationProps } from 'types/notification';

import { NotificationContent, Message, Subject, Time, Resolve } from './styles';
import { updateNotificationCache } from './updateCache';

interface NotificationComponentProps {
  notification: NotificationProps;
  setNotificationsOpen(open: boolean): void;
  limit: number;
}

function Notification({
  notification,
  setNotificationsOpen,
  limit,
}: NotificationComponentProps) {
  const { data: session } = useSession();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [deleteNotification] = useMutation(DELETE_NOTIFICATION_QUERY, {
    update(cache, { data: { deleteNotification } }) {
      updateNotificationCache(
        cache,
        deleteNotification.data.id,
        session,
        limit,
        setNotificationsOpen
      );
    },
    onCompleted() {
      setDeleteLoading(false);
    },
    onError(error) {
      console.log('notification error', error);
      message.error(
        'There was an error resolving your notification. Please try again later.'
      );
      setDeleteLoading(false);
    },
  });

  const timeElapsed = formatDistance(
    new Date(notification.createdAt),
    new Date(),
    {
      addSuffix: true,
    }
  );

  return (
    <NotificationContent $seen={notification.seen}>
      <Row align="middle" wrap={false} style={{ width: '100%' }}>
        <Col flex="auto">
          <Subject level={5}>{notification.subject}</Subject>
          {notification.message && (
            <Message
              ellipsis={{
                rows: 2,
                expandable: true,
                symbol: (
                  <span className="Notification-ReadMoreButton">read more</span>
                ),
              }}
            >
              {notification.message}
            </Message>
          )}
          <Time>{timeElapsed}</Time>
        </Col>
        <Col flex="50px" style={{ textAlign: 'right' }}>
          <Tooltip
            title="Delete"
            placement="left"
            overlayInnerStyle={{ padding: '5px 10px', minHeight: 'auto' }}
          >
            <Resolve
              loading={deleteLoading}
              type="primary"
              shape="circle"
              icon={<CheckOutlined />}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDeleteLoading(true);
                deleteNotification({ variables: { id: notification.id } });
              }}
            />
          </Tooltip>
        </Col>
      </Row>
    </NotificationContent>
  );
}

export default Notification;
