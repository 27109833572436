import { memo, useState } from 'react';
import {
  Grid,
  Button,
  Divider,
  Row,
  Col,
  Form,
  Radio,
  Typography,
  Alert,
  Slider,
  Modal,
  InputNumber,
} from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { motion } from 'framer-motion';
import { useIntercom } from 'react-use-intercom';

import { OPP_DD_LIMIT } from 'config';

import { currencyFormatter } from 'utils/strings';
import useUserData from 'hooks/useUserData';

import theme from 'styles/theme';

export const quarterlyOptions = (netWorth) => {
  return [
    { value: 250, label: '€250' },
    { value: 500, label: '€500' },
    { value: 1000, label: '€1,000' },
    { value: 2500, label: '€2,500' },
  ];
};

interface QuarterlyInvestmentProps {
  form: FormInstance;
}

const QuarterlyInvestment = ({ form }: QuarterlyInvestmentProps) => {
  const screens = Grid.useBreakpoint();
  const { show: showIntercom, update: updateIntercom } = useIntercom();

  const { userData } = useUserData();

  const [useCustomAmount, setUseCustomAmount] = useState(false);
  const [netWorthModalOpen, setNetWorthModalOpen] = useState(false);
  const [hasNetWorthChanged, setHasNetWorthChanged] = useState(false);

  const netWorth = Form.useWatch(['investorDetails', 'netWorth'], form);
  const quarterlySubscription = Form.useWatch(
    ['investorDetails', 'quarterlySubscription'],
    form
  );

  const quarterlyMaxNetWorth = Math.floor((netWorth * 0.1) / 4 / 50) * 50;
  const maxSubscription = Math.min(OPP_DD_LIMIT, quarterlyMaxNetWorth);

  const handleNetWorthChange = () => {
    setUseCustomAmount(false);

    if (!hasNetWorthChanged) setHasNetWorthChanged(true);

    const newNetWorth = form.getFieldValue(['investorDetails', 'netWorth']);
    const newQuarterlyMaxNetWorth =
      Math.floor((newNetWorth * 0.1) / 4 / 50) * 50;
    const newMaxSubscription = Math.min(OPP_DD_LIMIT, newQuarterlyMaxNetWorth);
    const newQuarterlySubscription = form.getFieldValue([
      'investorDetails',
      'quarterlySubscription',
    ]);

    // Reset quarterlySubscription if it exceeds the new maxSubscription
    if (
      newQuarterlySubscription &&
      newQuarterlySubscription > newMaxSubscription
    ) {
      form.setFieldValue(['investorDetails', 'quarterlySubscription'], null);
    }
  };

  const isMobile = screens.md === false;

  return (
    <>
      <Form.Item
        name={['investorDetails', 'netWorth']}
        label={
          userData?.investorDetails?.netWorth
            ? 'Your current net worth'
            : "What's your net worth?"
        }
        style={{ marginBottom: 20 }}
        initialValue={userData?.investorDetails?.netWorth}
        rules={[
          {
            required: true,
            message: 'This is a required field',
          },
        ]}
      >
        <InputNumber
          controls={false}
          size="large"
          max={10000000000}
          style={{ width: isMobile ? '100%' : 'auto' }}
          formatter={(value) =>
            `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          // @ts-ignore
          parser={(value) => value.replace(/€\s?|(,*)/g, '')}
          disabled={userData?.investorDetails.quarterlySubscriptionPaused}
          onChange={() => {
            handleNetWorthChange();
          }}
        />
      </Form.Item>
      <Typography.Paragraph style={{ color: theme.colors.bodyText }}>
        Your net worth is calulated as your net annual income (salary,
        dividends, etc.) plus your total liquid assets (cash, publicly traded
        shares, etc.) minus your annual financial commitments (rent, mortgage,
        loans, etc.). More information on how to calculate your net worth can be
        found{' '}
        <a
          style={{ textDecoration: 'underline' }}
          onClick={() => setNetWorthModalOpen(true)}
        >
          here
        </a>
        .
      </Typography.Paragraph>
      {netWorth && hasNetWorthChanged ? (
        <motion.div
          initial={{ y: 60, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
        >
          <Alert
            showIcon={screens.sm}
            type="warning"
            style={{ margin: '20px 0 15px' }}
            message={
              <Typography.Title
                level={5}
                style={{ margin: 0, fontWeight: 600 }}
              >
                {currencyFormatter(netWorth * 0.1)}
              </Typography.Title>
            }
            description={
              <Typography.Text style={{ color: theme.colors.bodyText }}>
                The figure above illustrates what a potential 10% loss in your
                net worth would represent. To ensure your financial safety,
                we&apos;ve capped your Quarterly Investment at{' '}
                {currencyFormatter(Math.max(250, maxSubscription))}. This limit
                is calculated as 10% of your net worth on an annualised basis.
              </Typography.Text>
            }
          />
        </motion.div>
      ) : (
        <Divider style={{ margin: '20px 0 30px' }} />
      )}
      <Typography.Paragraph
        style={{
          margin: '20px 0 0',
          color: theme.colors.labelText,
          fontWeight: 500,
        }}
      >
        Select your Quarterly Investment
      </Typography.Paragraph>
      <Row align="middle" style={{ height: screens.sm && 80 }}>
        <Col xs={24} sm={24} md={maxSubscription > 250 ? 17 : 24}>
          <Form.Item
            label={null}
            style={{ marginBottom: 0 }}
            name={['investorDetails', 'quarterlySubscription']}
            initialValue={userData?.investorDetails?.quarterlySubscription}
            rules={[
              {
                required: true,
                message: 'This is a required field',
              },
            ]}
          >
            {useCustomAmount ? (
              <Slider
                value={quarterlySubscription}
                disabled={
                  !netWorth ||
                  userData?.investorDetails.quarterlySubscriptionPaused
                }
                marks={{
                  250: '€250',
                  [quarterlySubscription]: {
                    style: {
                      position: 'relative',
                      backgroundColor: 'rgba(255, 255, 255, 0.9)',
                      padding: '0 8px',
                      zIndex: 1,
                      borderRadius: 10,
                      lineHeight: '14px',
                    },
                    label: currencyFormatter(quarterlySubscription),
                  },
                  [maxSubscription]: currencyFormatter(maxSubscription),
                }}
                step={50}
                min={250}
                max={maxSubscription}
                tooltip={{ open: false }}
                // style={{ minWidth: 280 }}
              />
            ) : (
              <Radio.Group
                buttonStyle="solid"
                optionType={isMobile ? 'default' : 'button'}
                style={{ width: '100%', marginTop: isMobile ? 10 : 0 }}
                value={quarterlySubscription}
              >
                {quarterlyOptions(netWorth).map(({ value, label }, index) => (
                  <Radio.Button
                    key={`${index}_${value}`}
                    value={value}
                    style={{
                      width: isMobile ? '100%' : 'calc(100% / 4)',
                      marginBottom: isMobile ? 10 : 0,
                      textAlign: 'center',
                    }}
                    disabled={
                      !netWorth ||
                      userData?.investorDetails.quarterlySubscriptionPaused ||
                      value * 4 > Math.max(1000, netWorth * 0.1)
                    }
                  >
                    {label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
        {maxSubscription > 250 && (
          <Col xs={24} sm={24} md={{ span: 5, push: 2 }}>
            <Button
              onClick={() => setUseCustomAmount(!useCustomAmount)}
              block
              disabled={
                !netWorth ||
                userData?.investorDetails.quarterlySubscriptionPaused
              }
              style={{ marginTop: isMobile ? 10 : 0 }}
            >
              {useCustomAmount ? 'Preset' : 'Custom'}
            </Button>
          </Col>
        )}
      </Row>
      {quarterlyMaxNetWorth > OPP_DD_LIMIT && (
        <motion.div
          initial={{ y: 60, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
        >
          <Alert
            closeIcon={true}
            style={{ margin: '30px 0' }}
            showIcon={screens.sm}
            description={
              <Typography.Text>
                The maximum Quarterly Investment we can currently process with
                our payments partner is €10,000. If you would like to invest
                more than €10,000 per quarter,{' '}
                <a
                  style={{ textDecoration: 'underline' }}
                  onClick={(e) => {
                    e.preventDefault();
                    updateIntercom({
                      name: `${userData?.accountDetails.firstName} ${userData?.accountDetails.lastName}`,
                      email: userData?.email,
                      userId: userData?.id,
                    });
                    showIntercom();
                  }}
                >
                  talk to us
                </a>
                .
              </Typography.Text>
            }
          />
        </motion.div>
      )}
      {!!quarterlySubscription && !!netWorth && (
        <motion.div
          initial={{ y: 60, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
        >
          <Divider style={{ marginTop: 10 }} />
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: 20 }}
          >
            <Typography.Text
              style={{ margin: 0, display: 'block', fontWeight: 500 }}
            >
              Selected amount:
            </Typography.Text>
            <Typography.Title level={3} style={{ margin: 0 }}>
              {userData?.investorDetails.quarterlySubscriptionPaused
                ? 'Paused'
                : currencyFormatter(quarterlySubscription)}
            </Typography.Title>
          </Row>
        </motion.div>
      )}
      <Modal
        open={netWorthModalOpen}
        width={700}
        footer={false}
        onCancel={() => setNetWorthModalOpen(false)}
        title={
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            Calculating your net worth
          </Typography.Title>
        }
      >
        <Typography.Paragraph>
          Please use values from December 31st of the previous year to calculate
          your total liquid assets and annual financial commitments. If a more
          recent date (up to today) would provide a better estimate of your
          assets and commitments, you can use that date instead. In this case,
          use the same date for both liquid assets and financial commitments,
          and calculate net annual income for the 12 months before that date.
        </Typography.Paragraph>
        <Typography.Paragraph>
          <strong>Net Annual Income</strong>: This is your total income after
          deducting costs, charges, social contributions, and taxes. Consider
          these sources of income:
        </Typography.Paragraph>
        <ul>
          <li>
            Labor income: Wages, unemployment benefits, and pension payments
            (excluding exceptional payments)
          </li>
          <li>
            Interest income: Payments from bank deposits or debt instruments
            (excluding exceptional payments)
          </li>
          <li>
            Dividend income: Payments from shares, units of collective
            investment schemes, or other equity instruments (excluding capital
            gains)
          </li>
          <li>
            Real estate income: Rent payments received (excluding capital gains)
          </li>
        </ul>

        <Typography.Paragraph>
          <strong>Total Liquid Assets</strong>: The sum of cash and assets that
          can be easily converted into cash, including:
        </Typography.Paragraph>
        <ul>
          <li>Cash in saving and current accounts</li>
          <li>
            Saving products that can be turned into cash within 30 calendar days
          </li>
          <li>Financial instruments traded on a regulated market</li>
          <li>
            Shares and units of collective investment schemes with weekly
            redemption rights
          </li>
        </ul>
        <Typography.Paragraph>
          Note: Real estate properties, pension scheme payments, and
          non-transferable/redeemable company shares (including crowdfunding
          investments) are not considered liquid assets.
        </Typography.Paragraph>

        <Typography.Paragraph>
          <strong>Annual Financial Commitments</strong>: The total of your
          financial obligations in a calendar year, including:
        </Typography.Paragraph>
        <ul>
          <li>Child support payments</li>
          <li>Rent and mortgage payments</li>
          <li>Loan repayments</li>
          <li>Insurance premium payments</li>
          <li>Utility expenses (electricity, heating, water)</li>
          <li>Service subscription payments</li>
          <li>Income tax and property taxes</li>
        </ul>
      </Modal>
    </>
  );
};

export default memo(QuarterlyInvestment);
