import styled from 'styled-components';
import { Row, Typography, Divider } from 'antd';
import theme from 'styles/theme';
import Link from 'next/link';

const { Title, Text } = Typography;

interface CommonProps {
  $isMobile?: boolean;
}

export const HeaderRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
`;

interface StyledTitleProps extends CommonProps {
  $noMargin?: boolean;
}

export const StyledTitle = styled(Title)<StyledTitleProps>`
  &&& {
    margin-bottom: ${({ $noMargin }) => ($noMargin ? '0' : '10px')};
    margin-top: 40px;
    color: ${theme.colors.bodyText};
  }
`;

export const StyledTitleValue = styled(Title)`
  &&& {
    margin-bottom: 0;
    font-weight: 600;
  }
`;

export const ContentDivider = styled(Divider)`
  &&& {
    margin: 12px 0 30px;
  }
`;

export const SummaryRow = styled(Row)<CommonProps>`
  &&& {
    justify-content: space-between;
    align-items: ${({ $isMobile }) => ($isMobile ? 'center' : 'start')};
    margin-bottom: ${({ $isMobile }) => ($isMobile ? '6px' : '15px')};
    flex-direction: ${({ $isMobile }) => ($isMobile ? 'row' : 'column')};
  }
`;

export const MobileText = styled(Text)<CommonProps>`
  &&& {
    margin-bottom: ${({ $isMobile }) => ($isMobile ? '0' : '5px')};
    color: ${theme.colors.bodyText};
  }
`;

export const StrikethroughTitle = styled(Title)`
  &&& {
    margin: 0;
    text-decoration: line-through;
    font-weight: 600;
  }
`;

export const ProcessorFeeTitle = styled(Title)`
  &&& {
    margin: 0;
    color: ${theme.colors.green};
  }
`;

export const SummaryTitle = styled(Title)`
  &&& {
    margin: 0;
  }
`;

export const SummaryValue = styled(Title)`
  &&& {
    margin: 0;
    font-weight: 600;
  }
`;

export const CheckboxRow = styled(Row)<CommonProps>`
  &&& {
    margin: ${({ $isMobile }) => ($isMobile ? '40px 0 20px' : '30px 0 40px')};
    width: ${({ $isMobile }) => ($isMobile ? '100%' : '80%')};
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: underline;
`;
