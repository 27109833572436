import { from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import fetch from 'isomorphic-unfetch';
import getConfig from 'next/config';
import { getSession } from 'next-auth/react';
import util from 'util';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const uri = `${
  serverRuntimeConfig.BACKEND_URL || publicRuntimeConfig.BACKEND_URL
}/graphql`;

export default function createLink(req) {
  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, path }) => {
        console.table({
          operation: operation.operationName,
          message,
          variables: util.inspect(operation.variables),
          path: util.inspect(path),
        });
      });
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const httpLink = createUploadLink({
    uri,
    fetch,
  });

  const authLink = setContext(async (_, ctx) => {
    let session = await getSession();

    if (typeof window === 'undefined' && req) {
      session = await getSession({ req });
    }

    return {
      headers: {
        ...ctx.headers,
        authorization: session ? `Bearer ${session?.jwt}` : '',
      },
    };
  });

  return from([errorLink, authLink, httpLink]);
}
