const theme = {
  colors: {
    teal: '#5ECEB7',
    chartreuse: '#E2FE74',
    lavender: '#BCB4E9',
    lightGrey: '#EAEFF3',
    darkTeal: '#26454F',
    darkGrey: '#52667C',
    lavenderGrey: '#7F87AB',
    coolGrey: '#B2BFC7',
    white: '#ffffff',
    black: '#000000',
    error: '#f15651',
    errorHalf: '#f1565150',
    warning: '#ffc94c',
    success: '#47d870',
    successHalf: '#47d87050',
    bodyText: '#6B7279',
    labelText: '#909FAE',

    primary: '#6747C4',
    primaryDeep: '#321863',
    primaryDeeper: '#0c0220',
    primaryHalf: '#703fcb20',
    primarySoft: '#e3d7ff',
    primarySofter: '#f7f4ff',
    primarySoftest: '#FCFAFF',
    secondary: '#ff5959',
    secondarySoft: '#FFF8F5',
    accent: '#f5958a',
    text: '#000000',
    textSecondary: 'rgba(0, 0, 0, 0.45)',
    buttonBlue: '#320C7A',
    green: '#64d97f',
  },
  font: {
    size: {
      headingOne: {
        smallest: '32px',
        small: '35px',
        large: '48px',
        largest: '60px',
      },
      headingTwo: {
        small: '24px',
        large: '34px',
      },
      headingThree: {
        small: '20px',
        large: '24px',
      },
      headingFour: {
        small: '16px',
        large: '20px',
      },
      paragraph: {
        small: '14px',
        regular: '16px',
        large: '18px',
      },
    },
  },
  size: {
    maxWidth: '1440px',
  },
  breakpoints: {
    xs: '480px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px',
  },
  backgrounds: {
    heroTeal: 'radial-gradient(circle at 10% 50%, #27454e 10%, #1a2e34 60%)',
    onboarding: `linear-gradient(
      45deg,
      rgba(84, 151, 144, 0.25) 0%,
      rgba(223, 248, 60, 0.25) 0%,
      rgba(223, 248, 60, 0.25) 0%,
      rgba(223, 248, 60, 0) 15%
    ),
    linear-gradient(
      310deg,
      rgba(84, 151, 144, 0.5) 0%,
      rgba(223, 248, 60, 0.5) 0%,
      rgba(188, 180, 230, 0.5) 0%,
      rgba(188, 180, 230, 0) 30%
    ),
    #eaeff4;`,
  },
  shadows: {
    card: `0px 15px 25px 0px #00000020`,
  },
};

export default theme;
