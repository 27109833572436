import * as R from 'ramda';
import type { UserProps } from 'types';

export const getValueFromMeta = (key, metadata) =>
  R.prop('value', R.find(R.propEq(key, 'key'))(metadata));

export const formatPaymentMethod = (payment_method) => {
  switch (payment_method) {
    case 'ideal':
      return 'iDEAL';
    case 'bcmc':
      return 'Bancontact';
    case 'sepa':
      return 'SEPA';
    case 'paypal':
      return 'PayPal';
    case 'creditcard':
      return 'Card';
    case 'sofort':
      return 'Sofort';
    case 'mybank':
      return 'MyBank';
    case 'giropay':
      return 'GiroPay';
    case 'mandate':
      return 'Mandate';
    case 'multi':
      return 'Multi-split';
    default:
      return 'Unknown';
  }
};

export const formatAmountForOPP = (amount: number): number => amount * 100;
export const formatAmountForPIL = (amount: number): number => amount / 100;

export const merchantEndpoint = (merchantId: string, isServer = false) =>
  `${isServer ? process.env.OPP_API_URL : '/api/psp'}/merchants/${merchantId}?expand[]=contacts&expand[]=profiles`;

export const mandateEndpoint = (mandateId: string, isServer = false) =>
  `${isServer ? process.env.OPP_API_URL : '/api/psp'}/mandates/${mandateId}?expand[]=customer&expand[]=order`;

export const transactionEndpoint = (transactionId: string, isServer = false) =>
  `${isServer ? process.env.OPP_API_URL : '/api/psp'}/transactions/${transactionId}?expand[]=fees&expand[]=metadata`;

export const merchantBalanceEndpoint = (
  merchantId: string,
  profileId: string
) => `/api/psp/merchants/${merchantId}/profiles/${profileId}/balance`;

export const adminMerchantWithdrawalsEndpoint = (merchantId: string) =>
  `/api/psp/merchants/${merchantId}/withdrawals?perpage=1000&filter[status][in]=created,pending,planned,completed`;

export const merchantBankAccountsEndpoint = (
  merchantId: string,
  bankAccountId: string
) => `/api/psp/merchants/${merchantId}/bankAccounts/${bankAccountId}`;

export const projectMerchantBalanceEndpoint = (
  projectMerchantId: string,
  projectMerchantProfileId: string
) =>
  `/api/psp/merchants/${projectMerchantId}/profiles/${projectMerchantProfileId}/balance`;

export const balanceEndpoint = (
  merchantId: string,
  profileId: string,
  isServer = false
) =>
  `${isServer ? process.env.OPP_API_URL : '/api/psp'}/merchants/${merchantId}/profiles/${profileId}/balance`;

export const accountWithdrawalsEndpoint = (
  merchantId: string,
  sortOrder: string,
  currentPage: number,
  currentPageSize: number,
  statusFilter: string
) =>
  `/api/psp/merchants/${merchantId}/withdrawals?order[]=${sortOrder}&page=${currentPage}&perpage=${currentPageSize}&${statusFilter}`;

export const accountTransactionsEndpoint = (
  merchantId: string,
  sortOrder: string,
  currentPage: number,
  currentPageSize: number,
  statusFilter: string
) =>
  `/api/psp/transactions?filter[merchant]=${merchantId}&expand[]=fees&order[]=${sortOrder}&page=${currentPage}&perpage=${currentPageSize}&${statusFilter}`;

export const cloudSchedulerEndpoint = (
  jobName: string,
  isServer = false,
  encode = true
) =>
  `${isServer ? 'https://cloudscheduler.googleapis.com/v1' : '/api/cloudScheduler'}${
    encode ? `?name=${encodeURIComponent(jobName)}` : `/${jobName}`
  }`;

export function getBankDisapprovalReason(reason: string): string {
  switch (reason) {
    case 'bank_account_business':
      return 'A business bank account was provided, but a consumer bank account is required. Please provide the correct type of bank account.';
    case 'bank_account_creditcard_without_iban':
      return 'A credit card was provided without an IBAN. Please upload a document showing the IBAN.';
    case 'bank_account_document_both_sides_needed':
      return 'Both sides of the bank card are required. Please upload images of both sides of the document.';
    case 'bank_account_document_expired':
      return 'The document provided is older than 3 months. Please upload a recent bank statement.';
    case 'bank_account_document_front_missing':
      return 'The front side of the bank card was not provided. Please upload images of both sides of the document.';
    case 'bank_account_document_incorrect':
      return 'The document provided is incorrect and cannot be processed. Please provide a valid document.';
    case 'bank_account_document_unreadable':
      return 'The document provided is unreadable or excessively blanked out. Please upload a clearer version.';
    case 'bank_account_from_unlicensed_country':
      return 'The bank account is from a country not within the licensed area. Please provide an IBAN from a supported country.';
    case 'bank_account_iban_different_from_document':
      return 'The IBAN provided does not match the one on the bank statement or card. Please ensure both IBANs match.';
    case 'bank_account_is_not_allowed':
      return 'The bank account provided is not allowed for this type of business. Please use a matching bank account.';
    case 'bank_account_joint_not_allowed':
      return 'Joint bank accounts are not allowed for this type of business. Please provide a single-owner bank account.';
    case 'bank_does_not_match_coc_name':
      return 'The name on the bank account does not match the provided company registration details. Please ensure both names match.';
    case 'other_reason':
      return 'The document was declined for a reason not listed. Please review the document and try again.';
    default:
      return 'An unknown error occurred. Please contact support for further assistance.';
  }
}

export enum PaymentStatus {
  Created = 'Created',
  Processing = 'Processing',
  Active = 'Active',
  Failed = 'Payment failed',
  Due = 'Payment due',
}

export const getPaymentStatus = (annualFee: UserProps['OPP']['annualFee']) => {
  switch (annualFee?.status) {
    case 'created':
      return PaymentStatus.Created;
    case 'completed':
      return PaymentStatus.Active;
    case 'reserved':
    case 'created':
    case 'pending':
    case 'planned':
      return PaymentStatus.Processing;
    case 'failed':
      return PaymentStatus.Failed;
    case 'cancelled':
    case 'expired':
    case 'refunded':
    case 'chargeback':
      return PaymentStatus.Due;
    default:
      return PaymentStatus.Due;
  }
};

export const getQuarterlyInvestmentStatus = (
  investorDetails: UserProps['investorDetails']
) => {
  switch (investorDetails?.transactionStatus) {
    case 'created':
      return PaymentStatus.Created;
    case 'completed':
      return PaymentStatus.Active;
    case 'reserved':
    case 'pending':
    case 'planned':
      return PaymentStatus.Processing;
    case 'failed':
      return PaymentStatus.Failed;
    case 'cancelled':
    case 'expired':
    case 'refunded':
    case 'chargeback':
      return PaymentStatus.Due;
    default:
      return PaymentStatus.Due;
  }
};
