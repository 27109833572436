import { gql } from '@apollo/client';

export const GET_BADGES_QUERY = gql`
  {
    badges {
      data {
        id
        attributes {
          type
          description
          image {
            data {
              attributes {
                url
                width
                height
              }
            }
          }
        }
      }
    }
  }
`;
