import { InMemoryCache } from '@apollo/client';
import * as R from 'ramda';
import { offsetLimitPagination } from '@apollo/client/utilities';

const inMemoryCache = new InMemoryCache({
  dataIdFromObject: ({ _id, __typename }) =>
    _id && __typename ? _id + __typename : null,
  typePolicies: {
    Query: {
      fields: {},
    },
    Mutation: {
      fields: {},
    },
  },
});

export default inMemoryCache;
