import { getSession } from 'next-auth/react';
import * as R from 'ramda';

import { USER_DATA_QUERY } from 'queries';

export const updateUserAvatar = (cache, data, session) => {
  const query = cache.readQuery({
    query: USER_DATA_QUERY,
    variables: { id: session?.user.id },
  });
  const cachedData = query?.userData || {};

  cache.writeQuery({
    query: USER_DATA_QUERY,
    variables: { id: session?.user.id },
    data: {
      userData: R.assocPath(['avatar', 'url'], data.upload.url)(cachedData),
    },
  });
};

export const updateUser = (cache, data, id) => {
  const query = cache.readQuery({
    query: USER_DATA_QUERY,
    variables: { id },
  });
  const cachedData = query?.userData || {};

  cache.writeQuery({
    query: USER_DATA_QUERY,
    variables: { id },
    data: {
      me: R.mergeRight(cachedData, data.updateMe),
    },
  });
};
