import styled from 'styled-components';
import { List, Typography } from 'antd';
import Image from 'next/image';

import theme from 'styles/theme';

interface CommonProps {
  $isMobile: boolean;
}

export const DisapprovalsList = styled(List)`
  &&& {
    padding: 10px 25px;
    background-color: ${theme.colors.error}20;
    border-radius: 15px;
    margin-bottom: 30px;
  }
`;

export const DisapprovalsListTitle = styled(Typography.Title)`
  &&& {
    margin-bottom: 5px;
    color: ${theme.colors.error};
  }
`;

export const Character = styled(Image)<CommonProps>`
  width: ${(props) => (props.$isMobile ? '270px' : '300px')};
  height: auto;
`;
