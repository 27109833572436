import { createContext, useContext, useState, ReactNode } from 'react';

interface WelcomeModalContextType {
  welcomeModalOpen: boolean;
  setWelcomeModalOpen: (open: boolean) => void;
}

const WelcomeModalContext = createContext<WelcomeModalContextType | undefined>(
  undefined
);

interface WelcomeModalProviderProps {
  children: ReactNode;
}

export function WelcomeModalProvider({ children }: WelcomeModalProviderProps) {
  const [welcomeModalOpen, setWelcomeModalOpen] = useState(false);

  return (
    <WelcomeModalContext.Provider
      value={{ welcomeModalOpen, setWelcomeModalOpen }}
    >
      {children}
    </WelcomeModalContext.Provider>
  );
}

export function useWelcomeModal(): WelcomeModalContextType {
  const context = useContext(WelcomeModalContext);
  if (context === undefined) {
    throw new Error(
      'useWelcomeModal must be used within a WelcomeModalProvider'
    );
  }
  return context;
}
