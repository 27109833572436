import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { Button, Grid, Row } from 'antd';
import { usePostHog } from 'posthog-js/react';

import { CookieBannerType } from 'types/cookieBanner';

import { Container, Title, Description } from './styles';

export type CookieBannerProps = {
  cookieBanner: CookieBannerType;
};

const CookieBanner = ({ cookieBanner }: CookieBannerProps) => {
  const screens = Grid.useBreakpoint();

  const [shouldShow, setShouldShow] = useState(false);
  const posthog = usePostHog();

  useEffect(() => {
    import('utils/cookieBannerStorage').then(({ default: storage }) => {
      const { cookiesSeen = false } = storage.getData() || {};
      setShouldShow(!cookiesSeen);
    });
  }, []);

  if (!cookieBanner || !shouldShow) {
    return null;
  }

  const eatCookies = (enable) => {
    import('utils/cookieBannerStorage').then(({ default: storage }) => {
      posthog.set_config({
        persistence: enable ? 'localStorage+cookie' : 'memory',
      });
      storage.enableCookies(enable);
      storage.setCookiesSeen();
      setShouldShow(false);
    });
  };

  const isMobile = screens.md === false;

  return (
    <Container $isMobile={isMobile}>
      <Title level={5}>{cookieBanner.copy.title}</Title>
      <Description>{cookieBanner.copy.content}</Description>
      <Row justify="space-between">
        <Button
          type="primary"
          onClick={() => eatCookies(true)}
          style={{ width: 'calc(50% - 5px)' }}
        >
          {cookieBanner.copy.agree}
        </Button>
        <Button
          onClick={() => eatCookies(false)}
          style={{ width: 'calc(50% - 5px)' }}
        >
          {cookieBanner.copy.decline}
        </Button>
      </Row>
    </Container>
  );
};

export default CookieBanner;
