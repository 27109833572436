import { memo, useState, useEffect } from 'react';
import Image from 'next/image';
import {
  Divider,
  Grid,
  Row,
  Button,
  Col,
  Form,
  Typography,
  Upload,
  Select,
  Modal,
  Card,
  Alert,
  Spin,
} from 'antd';
import { StopOutlined, UploadOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form/Form';
import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import { useSession } from 'next-auth/react';

import { UserProps } from 'types';
import { Merchant } from 'types/opp';

import { USER_DATA_QUERY } from 'queries';

import { fetchGetJSON } from 'utils/apiHelpers';
import { onboardingFieldChange } from 'utils/formValidation';

import theme from 'styles/theme';

import mrz_not_readable_image from 'assets/images/onboarding/kyc/mrz_not_readable.avif';
import glare_flash_image from 'assets/images/onboarding/kyc/glare_flash.avif';
import edges_not_visible_image from 'assets/images/onboarding/kyc/edges_not_visible.avif';
import covered_finger_image from 'assets/images/onboarding/kyc/covered_finger.avif';
import both_sides_one_page_image from 'assets/images/onboarding/kyc/both_sides_one_page.avif';
import blurred_image from 'assets/images/onboarding/kyc/blurred.avif';
import black_white_image from 'assets/images/onboarding/kyc/black_white.avif';

export interface KYCProps {
  form: FormInstance;
  setComplete?(complete: boolean): void;
}

export const documentTypes = [
  { name: 'Passport', type: 'passport' },
  { name: 'Drivers licence', type: 'drivers_license' },
  { name: 'National ID card', type: 'id_card' },
];

export const commonErrors = [
  {
    image: mrz_not_readable_image,
    text: 'Machine-readable zone (MRZ), data, or photo not fully visible',
  },
  { image: glare_flash_image, text: 'Glare or flash obscuring readability' },
  { image: edges_not_visible_image, text: 'Edges not fully visible' },
  {
    image: covered_finger_image,
    text: 'Covered by anything, especially a finger',
  },
  { image: blurred_image, text: 'Blurred' },
  { image: black_white_image, text: 'Black and white' },
  {
    image: both_sides_one_page_image,
    text: 'Submitted with both sides on one page',
  },
];

function KYC({ form, setComplete }: KYCProps) {
  const screens = Grid.useBreakpoint();
  const { data: session } = useSession();

  const [documentType, setDocumentType] = useState<{
    name: string;
    type: string;
  }>();
  const [modalOpen, setModalOpen] = useState(false);
  const [documentsSubmitted, setDocumentsSubmitted] = useState(false);
  const [merchant, setMerchant] = useState<Merchant>(null);
  const [oppApiLoading, setOppApiLoading] = useState(false);

  const { data: uData } = useQuery(USER_DATA_QUERY, {
    variables: { id: session?.user.id },
  });
  const userData: UserProps = uData?.me;

  useEffect(() => {
    if (!userData?.OPP?.merchantId) return;
    setOppApiLoading(true);
    const endpoint = `/api/psp/merchants/${userData.OPP.merchantId}?expand[]=contacts`;
    fetchGetJSON(endpoint)
      .then((merchant) => setMerchant(merchant))
      .catch((error) => setMerchant(error))
      .finally(() => setOppApiLoading(false));
  }, [userData?.OPP?.merchantId]);

  useEffect(() => {
    if (!merchant || merchant?.contacts[0].status === 'unverified') return;
    setComplete?.(true);
    setDocumentsSubmitted(true);
  }, [merchant, setComplete]);

  useEffect(() => {
    onFieldChange();
  }, []);

  const onFieldChange = () => {
    let fields = [['kyc', 'passport']];
    if (documentType?.type === 'drivers_license') {
      fields = [
        ['kyc', 'drivers_license', 'front'],
        ['kyc', 'drivers_license', 'backside'],
      ];
    }
    if (documentType?.type === 'id_card') {
      fields = [
        ['kyc', 'id_card', 'front'],
        ['kyc', 'id_card', 'backside'],
      ];
    }
    onboardingFieldChange({
      form,
      fields,
      setComplete,
    });
  };

  const resetUploads = () => {
    form.resetFields([
      ['kyc', 'passport'],
      ['kyc', 'drivers_license', 'front'],
      ['kyc', 'drivers_license', 'backside'],
      ['kyc', 'id_card', 'front'],
      ['kyc', 'id_card', 'backside'],
    ]);
  };

  return (
    <>
      <Typography.Title level={4}>Identity verification</Typography.Title>
      <Divider style={{ margin: '12px 0 30px' }} />
      {documentsSubmitted ? (
        <Alert
          showIcon={screens.sm}
          message="Your identity documents are pending review"
          description="Thank you for submitting your identity documents. We have successfully received them and they are currently under review by our compliance team."
        />
      ) : (
        <Spin spinning={oppApiLoading}>
          <Typography.Paragraph style={{ color: theme.colors.bodyText }}>
            The Central Bank of Ireland requires us to verify the identity of
            our investors to prevent fraud and illicit activities.
          </Typography.Paragraph>
          <Row gutter={[20, 0]}>
            <Col span={24} style={{ marginBottom: 20 }}>
              <Alert
                showIcon={screens.sm}
                description={
                  <Typography.Link onClick={() => setModalOpen(true)}>
                    Please try to avoid{' '}
                    <span style={{ textDecoration: 'underline' }}>these</span>{' '}
                    common mistakes when uploading images of your identity
                    documents.
                  </Typography.Link>
                }
              />
            </Col>
            <Col xs={24} md={24}>
              <Form.Item
                rules={[
                  { required: true, message: 'This is a required field!' },
                ]}
                label="Document type"
                name={['kyc', 'documentType']}
              >
                <Select
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    resetUploads();
                    setDocumentType(
                      R.find(R.propEq(value, 'type'), documentTypes)
                    );
                  }}
                >
                  {documentTypes.map(({ name, type }) => (
                    <Select.Option key={type} value={type}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {documentType?.type === 'passport' && (
              <Col span={24}>
                <Form.Item
                  rules={[
                    { required: true, message: 'This is a required field!' },
                  ]}
                  label="Photo page of passport"
                  name={['kyc', documentType.type]}
                >
                  <Upload
                    accept=".png,.jpg,.jpeg,.pdf"
                    action={'/api/noop'}
                    style={{ padding: '0 30px' }}
                    listType="picture"
                    maxCount={1}
                    onChange={({ fileList }) => {
                      form.setFieldValue(['kyc', documentType.type], fileList);
                      onFieldChange();
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
              </Col>
            )}
            {documentType && documentType?.type !== 'passport' && (
              <>
                <Col sm={24} md={12}>
                  <Form.Item
                    rules={[
                      { required: true, message: 'This is a required field!' },
                    ]}
                    label={`Front of ${documentType.name}`}
                    name={['kyc', documentType.type, 'front']}
                  >
                    <Upload
                      accept=".png,.jpg,.jpeg,.pdf"
                      style={{ padding: '0 30px' }}
                      action={'/api/noop'}
                      maxCount={1}
                      listType="picture"
                      onPreview={null}
                      onChange={({ fileList }) => {
                        form.setFieldValue(
                          ['kyc', documentType.type, 'front'],
                          fileList
                        );
                        onFieldChange();
                      }}
                    >
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item
                    rules={[
                      { required: true, message: 'This is a required field!' },
                    ]}
                    label={`Back of ${documentType.name}`}
                    name={['kyc', documentType.type, 'backside']}
                  >
                    <Upload
                      accept=".png,.jpg,.jpeg,.pdf"
                      style={{ padding: '0 30px' }}
                      action={'/api/noop'}
                      maxCount={1}
                      listType="picture"
                      onPreview={null}
                      onChange={({ fileList }) => {
                        form.setFieldValue(
                          ['kyc', documentType.type, 'backside'],
                          fileList
                        );
                        onFieldChange();
                      }}
                    >
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          <Modal
            open={modalOpen}
            title={
              <Typography.Title level={3} style={{ marginBottom: 0 }}>
                Common mistakes
              </Typography.Title>
            }
            footer={null}
            onCancel={() => setModalOpen(false)}
          >
            <Typography.Paragraph>
              Below are some common errors than can lead to a document being
              refused:
            </Typography.Paragraph>
            <Row gutter={[10, 10]} justify="start">
              {commonErrors.map((error, index) => (
                <Col
                  key={error.text}
                  sm={24}
                  md={index === commonErrors.length - 1 ? 24 : 24}
                >
                  <Card>
                    <div style={{ position: 'relative' }}>
                      <Image
                        src={error.image}
                        alt=""
                        style={{
                          width: '100%',
                          height: 'auto',
                          marginBottom: 20,
                        }}
                      />
                      <StopOutlined
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          color: theme.colors.secondary,
                          fontSize: 40,
                          transform: 'translate(20%, -20%)',
                        }}
                      />
                    </div>
                    <Typography.Text>{error.text}</Typography.Text>
                  </Card>
                </Col>
              ))}
            </Row>
          </Modal>
        </Spin>
      )}
    </>
  );
}

export default memo(KYC);
