import { useState, useEffect, useMemo } from 'react';
import Image from 'next/image';
import {
  Divider,
  Checkbox,
  Slider,
  Form,
  Typography,
  Modal,
  Space,
  Row,
  Col,
  Input,
  InputNumber,
  Button,
  message,
  Grid,
} from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';
import { useRouter } from 'next/router';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import copy from 'copy-to-clipboard';
import crypto from 'crypto';
import posthog from 'posthog-js';

import {
  JOIN_WAITLIST_QUERY,
  GET_WAITLIST_SUBMISSION,
  LINK_INVITE_CODE_QUERY,
  GET_WAITLIST_SUBMISSION_BY_EMAIL_QUERY,
  GET_WAITLIST_SUM_QUERY,
} from 'queries';

import ReturnProjector from './ReturnProjector';

import { currencyFormatter } from 'utils/strings';
import { flattenEntities } from 'utils/graphql';

import theme from 'styles/theme';
import { WhatsAppButton } from './styles';

export interface WaitlistModalProps {
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
  email?: string;
  onComplete(): void;
}

function WaitlistModal({
  isOpen,
  setIsOpen,
  email,
  onComplete,
}: WaitlistModalProps) {
  const screens = Grid.useBreakpoint();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [estimatedReferrals, setEstimatedReferrals] = useState(30);
  const [form] = Form.useForm();

  const [getWaitlistSubmission, { data, refetch }] = useLazyQuery(
    GET_WAITLIST_SUBMISSION
  );
  const [getWaitlistSubmissionByEmail, { data: dataByEmail }] = useLazyQuery(
    GET_WAITLIST_SUBMISSION_BY_EMAIL_QUERY
  );
  const { data: sumData, refetch: refetchSum } = useQuery(
    GET_WAITLIST_SUM_QUERY
  );

  const waitlistUser = useMemo(() => {
    const waitlistData =
      flattenEntities(data?.waitlistSubmission)?.data ||
      flattenEntities(dataByEmail?.getWaitlistSubmissionByEmail)?.data;

    if (typeof window !== 'undefined' && waitlistData) {
      localStorage.setItem('Shuttle-waitlistId', waitlistData.id);
    }

    return waitlistData;
  }, [data, dataByEmail]);

  const [linkInviteCode] = useMutation(LINK_INVITE_CODE_QUERY);

  const [createWaitlistSubmission] = useMutation(JOIN_WAITLIST_QUERY, {
    onCompleted(data) {
      setLoading(false);
      message.success("Congrats, you're on the waitlist!");

      const waitlistId = data.createWaitlistSubmission.data.id;
      getWaitlistSubmission({ variables: { id: waitlistId } });
      refetchSum();

      if (router.query.inviteCode) {
        linkInviteCode({
          variables: { id: waitlistId, hash: router.query.inviteCode },
        });
      }

      onComplete();
    },
    async onError(error) {
      if (
        error.message === 'Duplicate entry' ||
        error.message === 'Internal Server Error' // TODO: Why is Strapi not returning the correct error?
      ) {
        await getWaitlistSubmissionByEmail({
          variables: { email: form.getFieldValue('email') },
        });
      } else {
        message.error('Something went wrong. Please try again.');
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    const waitlistId = localStorage.getItem('Shuttle-waitlistId');
    if (waitlistId) {
      getWaitlistSubmission({ variables: { id: waitlistId } });
    }
  }, []);

  const onFormFinish = async (values) => {
    setLoading(true);

    // Track waitlist signup attempt
    posthog.capture('waitlist_signup', {
      email: values.email,
      investment_amount: values.investmentSignalled,
      marketing_opt_in: values.marketingOptIn,
    });

    // Generate a buffer of random bytes
    const buffer = crypto.randomBytes(Math.ceil((8 * 3) / 4));

    // Convert the buffer to a Base64 string
    const base64String = buffer.toString('base64');

    // Replace '+' and '/' with alphanumeric characters to avoid URL issues
    // and remove '=' padding to get a cleaner string, then trim to desired length
    const hash = base64String
      .replace(/\+/g, '0')
      .replace(/\//g, 'A')
      .substring(0, 8);

    createWaitlistSubmission({
      variables: {
        ...values,
        hash,
      },
    });
  };

  // Add tracking for WhatsApp share
  const handleWhatsAppShare = () => {
    posthog.capture('waitlist_whatsapp_share', {
      user_email: waitlistUser.email,
      referral_count: waitlistUser.referrals.length,
    });
  };

  // Add tracking for link copy
  const handleLinkCopy = () => {
    posthog.capture('waitlist_link_copied', {
      user_email: waitlistUser.email,
      referral_count: waitlistUser.referrals.length,
    });

    copy(`${process.env.NEXTAUTH_URL}/?inviteCode=${waitlistUser.hash}`);
    message.success('Unique link copied to clipboard 🔗');
  };

  const renderCommitments = () => (
    <div
      style={{
        textAlign: 'center',
        padding: '40px 20px',
        position: 'relative',
        zIndex: 1,
        background: theme.backgrounds.heroTeal,
        borderRadius: 15,
      }}
    >
      <Typography.Title
        level={screens.md ? 1 : 2}
        style={{
          color: 'white',
          marginBottom: 10,
        }}
      >
        {currencyFormatter(sumData?.waitlistSum)}
      </Typography.Title>
      <Typography.Paragraph
        style={{
          color: 'rgba(255, 255, 255, 0.8)',
          maxWidth: 300,
          margin: '0 auto',
        }}
      >
        Signalled from investors worldwide
      </Typography.Paragraph>
    </div>
  );

  const onChangeComplete = (value: number) => {
    setEstimatedReferrals(value);
  };

  return (
    <Modal
      closeIcon={null}
      title={null}
      open={isOpen}
      footer={null}
      width={600}
      onCancel={() => setIsOpen(false)}
      styles={{
        body: { backgroundColor: theme.colors.primarySoftest, padding: 30 },
      }}
    >
      <Form form={form} onFinish={onFormFinish} layout="vertical">
        {!waitlistUser ? (
          <Row gutter={[30, 0]}>
            <Col span={24} style={{ marginBottom: 30 }}>
              {renderCommitments()}
            </Col>
            <Col span={24}>
              <>
                <Typography.Paragraph
                  style={{
                    marginBottom: 0,
                    color: '#909FAE',
                    fontWeight: 500,
                  }}
                >
                  How much would you consider investing per quarter?
                </Typography.Paragraph>
                <Form.Item
                  style={{ marginBottom: 15 }}
                  name="investmentSignalled"
                  label={
                    <>
                      <Typography.Paragraph
                        style={{
                          fontSize: 12,
                          marginBottom: 0,
                          color: theme.colors.textSecondary,
                        }}
                      >
                        * This is purely just an indication of interest, not a
                        firm commitment.
                      </Typography.Paragraph>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: 'A signal of investment is required',
                    },
                    {
                      message: 'The minimum investment is €250',
                      async validator(_, value) {
                        if (value < 250) {
                          throw 'Value less than €250';
                        }
                      },
                    },
                    {
                      message: 'We only allow investments in €50 increments',
                      async validator(_, value) {
                        if (value % 50 !== 0 && value >= 250) {
                          throw 'Value not divisible by 50';
                        }
                      },
                    },
                    {
                      message:
                        'If you would like to commit more than €100,000 per quarter please email hello@shuttle.club. We will be happy to discuss your requirements.',
                      async validator(_, value) {
                        if (value > 100000) {
                          throw 'Value more than €100,000';
                        }
                      },
                    },
                  ]}
                >
                  <InputNumber
                    step={250}
                    style={{
                      width: screens.sm ? '50%' : '100%',
                      fontWeight: 600,
                    }}
                    formatter={(value) =>
                      `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) =>
                      parseInt(value.replace(/€\s?|(,*)/g, ''))
                    }
                  />
                </Form.Item>
              </>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item
                style={{ marginBottom: 15, width: '100%' }}
                name="email"
                label="Your email address"
                initialValue={email}
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please input a valid email',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={20}>
              <Form.Item
                style={{ marginBottom: 15 }}
                name="marketingOptIn"
                valuePropName="checked"
              >
                <Checkbox>
                  Subscribe to our weekly newsletter to receive exclusive market
                  updates and learn about all things investing?
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                style={{ minWidth: 200, marginTop: 10 }}
              >
                Secure your spot
              </Button>
            </Col>
          </Row>
        ) : (
          <Space size={20} direction="vertical" style={{ width: '100%' }}>
            <div
              style={{
                textAlign: 'center',
                padding: '50px 20px',
                position: 'relative',
                background: theme.backgrounds.heroTeal,
                borderRadius: 15,
                zIndex: 1,
              }}
            >
              <Typography.Title
                level={screens.md ? 2 : 2}
                style={{
                  color: 'white',
                  marginBottom: 10,
                }}
              >
                Invited friends = profit 📈
              </Typography.Title>
              <Typography.Paragraph
                style={{
                  color: '#ddd',
                  textShadow: '0px 0px  5px black',
                  maxWidth: 450,
                  margin: '0 auto',
                }}
              >
                For each friend who joins Shuttle using your invite link,
                you&apos;ll earn <strong>10%</strong> of all their future profit
                and <strong>€50</strong> in cash!
              </Typography.Paragraph>
            </div>

            <Typography.Paragraph
              style={{ margin: '-15px 0 0 0' }}
              type="secondary"
            >
              This reservation is held for {waitlistUser.email}.{' '}
              <a
                href="mailto:support@shuttle.club"
                target="_blank"
                rel="noreferrer"
              >
                Not you?
              </a>
            </Typography.Paragraph>

            <Row align="middle" justify="space-between">
              <Col xs={24} md={16}>
                <Space>
                  <a
                    href={`https://wa.me/?text=${encodeURIComponent(`Guys! I'm joining Shuttle...`)}`}
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleWhatsAppShare}
                  >
                    <WhatsAppButton type="primary" icon={<WhatsAppOutlined />}>
                      Share on WhatsApp
                    </WhatsAppButton>
                  </a>
                  <Button type="primary" onClick={handleLinkCopy}>
                    Copy link
                  </Button>
                </Space>
              </Col>
              <Col
                xs={24}
                md={8}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: screens.md ? 'end' : 'start',
                  marginTop: screens.md ? 0 : 10,
                }}
              >
                <Typography.Text
                  type="secondary"
                  style={{ margin: '0 10px 0 0', display: 'inline-block' }}
                >
                  Friends invited:
                </Typography.Text>
                <Typography.Title
                  level={3}
                  style={{
                    margin: 0,
                    display: 'inline-block',
                  }}
                >
                  {waitlistUser.referrals.length}
                </Typography.Title>
              </Col>
            </Row>
            <Typography.Paragraph
              style={{ margin: 0, color: theme.colors.bodyText }}
            >
              Click the buttons above to copy your unique invite link, then
              share it with friends via WhatsApp, email or on social.
            </Typography.Paragraph>
            <div>
              <Divider style={{ marginTop: 0 }} />
              <Typography.Title level={4} style={{ marginBottom: 5 }}>
                Profit simulator
              </Typography.Title>
              <Typography.Paragraph style={{ margin: 0 }} type="secondary">
                Use the slider to estimate how many friends you think you can
                sign up. Projected returns are simulated based on historical
                data. Past performance is not indicative of future results.
              </Typography.Paragraph>
              <Slider
                min={10}
                max={100}
                step={10}
                value={estimatedReferrals}
                onChange={onChangeComplete}
                tooltip={{ formatter: (value) => `${value} friends` }}
                style={{ marginBottom: 25 }}
              />
              <ReturnProjector sumOverride={estimatedReferrals * 250} />
            </div>
          </Space>
        )}
      </Form>
    </Modal>
  );
}

export default WaitlistModal;
