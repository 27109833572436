import { gql } from '@apollo/client';

export const GET_SKILLS_QUERY = gql`
  query {
    skills(pagination: { limit: 100 }, sort: "name:asc") {
      data {
        id
        attributes {
          name
        }
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
`;
