import { gql } from '@apollo/client';

export const feedbackFragment = gql`
  fragment FeedbackFields on Feedback {
    createdAt
    projectField
    fieldIndex
    timestamp
    hidden
    type
    user {
      data {
        id
        attributes {
          persona
          accountDetails {
            firstName
            lastName
          }
          avatar {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
    project {
      data {
        id
        attributes {
          name
          slug
          owner {
            data {
              id
              attributes {
                accountDetails {
                  firstName
                  lastName
                }
              }
            }
          }
          faq {
            question
          }
        }
      }
    }
    quote
    content
    audio {
      data {
        attributes {
          url
        }
      }
    }
    parent {
      data {
        id
      }
    }
  }
`;

export const GET_FEEDBACK_QUERY = gql`
  ${feedbackFragment}
  query getFeedback(
    $project: ID
    $projectField: String
    $fieldIndex: String
    $id: ID
    $excludeTypes: [String]
  ) {
    feedbacks(
      pagination: { start: 0, limit: 1000 }
      sort: "createdAt:desc"
      filters: {
        id: { eq: $id }
        project: { id: { eq: $project } }
        projectField: { eq: $projectField }
        fieldIndex: { eq: $fieldIndex }
        type: { notIn: $excludeTypes }
        parent: null
      }
    ) {
      data {
        id
        attributes {
          ...FeedbackFields
          children {
            data {
              id
              attributes {
                ...FeedbackFields
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

export const GET_FEEDBACK_AND_COUNT_QUERY = gql`
  ${feedbackFragment}
  query getProfileFeedback(
    $start: Int
    $limit: Int
    $sort: [String]
    $filters: FeedbackFiltersInput
  ) {
    feedbacks(
      pagination: { start: $start, limit: $limit }
      sort: $sort
      filters: $filters
    ) {
      data {
        id
        attributes {
          ...FeedbackFields
          children {
            data {
              id
              attributes {
                ...FeedbackFields
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

export const GET_FEEDBACK_AUTHORS_QUERY = gql`
  query getFeedbackAuthors($userId: ID) {
    feedbacks(
      pagination: { limit: 100 }
      filters: {
        project: { owner: { id: { eq: $userId } } }
        user: { id: { ne: $userId } }
      }
    ) {
      data {
        id
        attributes {
          user {
            data {
              id
              attributes {
                accountDetails {
                  firstName
                  lastName
                }
                avatar {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_FEEDBACK_PROJECTS_QUERY = gql`
  query getFeedbackPitches($userId: ID) {
    feedbacks(filters: { user: { id: { eq: $userId } } }) {
      data {
        id
        attributes {
          project {
            data {
              id
              attributes {
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_FEEDBACK_QUERY = gql`
  ${feedbackFragment}
  mutation createFeedback(
    $project: ID!
    $user: ID
    $projectField: String
    $fieldIndex: String
    $timestamp: String
    $quote: String
    $content: String
    $parent: ID
    $type: ENUM_FEEDBACK_TYPE
  ) {
    createFeedback(
      data: {
        project: $project
        user: $user
        projectField: $projectField
        fieldIndex: $fieldIndex
        quote: $quote
        timestamp: $timestamp
        content: $content
        parent: $parent
        type: $type
      }
    ) {
      data {
        id
        attributes {
          ...FeedbackFields
          children {
            data {
              id
              attributes {
                ...FeedbackFields
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_FEEDBACK_QUERY = gql`
  ${feedbackFragment}
  mutation updateFeedback(
    $id: ID!
    $content: String
    $fieldIndex: String
    $hidden: Boolean
  ) {
    updateFeedback(
      id: $id
      data: { content: $content, fieldIndex: $fieldIndex, hidden: $hidden }
    ) {
      data {
        id
        attributes {
          ...FeedbackFields
          children {
            data {
              id
              attributes {
                ...FeedbackFields
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_FEEDBACK_QUERY = gql`
  ${feedbackFragment}
  mutation deleteFeedback($id: ID!) {
    deleteFeedback(id: $id) {
      data {
        id
        attributes {
          ...FeedbackFields
          children {
            data {
              id
              attributes {
                ...FeedbackFields
              }
            }
          }
        }
      }
    }
  }
`;
