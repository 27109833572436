import { gql } from '@apollo/client';

export const resourceFragment = gql`
  fragment ResourceFields on Resource {
    createdAt
    tag
    title
    slug
    description
    thumbnail {
      data {
        attributes {
          url
          width
          height
        }
      }
    }
    showOnHomepage
    resourceContent(pagination: { limit: 100 }) {
      title
      titleLevel
      subtitle
      description
      media {
        data {
          attributes {
            url
            width
            height
          }
        }
      }
      imageFormat
      mediaType
      videoUrl
    }
  }
`;

export const GET_RESOURCES_QUERY = gql`
  query getResources(
    $start: Int
    $limit: Int
    $sort: [String]
    $where: ResourceFiltersInput
  ) {
    resources(
      pagination: { start: $start, limit: $limit }
      sort: $sort
      filters: $where
    ) {
      data {
        id
        attributes {
          ...ResourceFields
        }
      }
      meta {
        pagination {
          page
          pageSize
          total
          pageCount
        }
      }
    }
  }
  ${resourceFragment}
`;

export const GET_HOMEPAGE_RESOURCES_QUERY = gql`
  {
    resources(
      sort: "createdAt:asc"
      pagination: { start: 0, limit: 5 }
      filters: { showOnHomepage: { eq: true } }
    ) {
      data {
        id
        attributes {
          title
          tag
          slug
          description
          thumbnail {
            data {
              attributes {
                url
                width
                height
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_RESOURCE_SLUGS_QUERY = gql`
  query getResources($start: Int, $limit: Int, $where: ResourceFiltersInput) {
    resources(pagination: { start: $start, limit: $limit }, filters: $where) {
      data {
        id
        attributes {
          slug
        }
      }
    }
  }
`;

export const GET_RESOURCE_BY_SLUG_QUERY = gql`
  query getResource($slug: String!) {
    resources(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          ...ResourceFields
        }
      }
    }
  }
  ${resourceFragment}
`;
