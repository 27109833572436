import { gql } from '@apollo/client';

export const getUpcomingInvestmentFragment = gql`
  fragment GetUpcomingInvestmentFields on UpcomingInvestment {
    weight
    openingDate
    closingDate
    status
    selectedInvestments {
      memo(pagination: { limit: 100 }) {
        title
        titleLevel
        subtitle
        description
        media {
          data {
            attributes {
              url
              width
              height
            }
          }
        }
        imageFormat
        mediaType
        videoUrl
      }
      assetClass
      valuation
      valuationCap
      discount
      security
      outstandingShares
      shareClass
      nominalShareValue
      updates {
        date
        valuation
        outstandingShares
        capitalRaised
        title
        description
        notes
        documents {
          data {
            attributes {
              url
            }
          }
        }
      }
      KIIS {
        data {
          attributes {
            url
            name
          }
        }
      }
      project {
        data {
          id
          attributes {
            slug
            OPP {
              merchantId
              projectMerchantId
              projectMerchantProfileId
              bankAccountId
              profileId
            }
            founderProfiles {
              name
              position
              linkedInUrl
              avatar {
                data {
                  attributes {
                    url
                    width
                    height
                  }
                }
              }
            }
            icon {
              data {
                attributes {
                  url
                  width
                  height
                }
              }
            }
            logo {
              data {
                attributes {
                  url
                  width
                  height
                }
              }
            }
            name
            legalName
            slug
            url
            description
            problem
            solution
            businessModel
            longTermAmbition
            fundingTarget
            fundingCommitted
            distributionModel
            incorporationCountry
            leadInvestor
            leadInvestorUrl
            verticals {
              data {
                id
                attributes {
                  name
                }
              }
            }
            pitchDeck {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_OPPORTUNITIES_QUERY = gql`
  ${getUpcomingInvestmentFragment}
  query getUpcomingInvestments($filters: UpcomingInvestmentFiltersInput) {
    upcomingInvestments(filters: $filters) {
      data {
        id
        attributes {
          ...GetUpcomingInvestmentFields
        }
      }
    }
  }
`;
